<template>
  <div>
    <Navbar />
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column blog-container-column">
            <h1 class="title title-font has-text-left">
              Morpher Referral Program - Terms & Conditions
            </h1>
            <p>Last Updated September 12 2023</p>
            <h2 class="title">Scope</h2>
            <p>
              These Terms and Conditions ("Terms") apply to the referral program
              of Morpher Labs GmbH ("Morpher Labs") on the website
              www.morpher.com ("Website") and the application ("App"). During
              the course of the Referral Program, Morpher Labs plans to
              distribute variable amounts of "Morpher Tokens" as a reward for
              registering on the Website as a referred user and referring new
              users (”Participants”). Morpher Tokens are ERC20 tokens on the
              Ethereum blockchain.
            </p>
            <p>
              The Morpher Tokens distributed in connection with the airdrop and
              referral program are 'gifts' pursuant to § 938 of the Austrian
              General Civil Code and do not confer any rights, neither statutory
              nor contractual, on recipients.
            </p>
            <p>
              Morpher Labs may update, change, replace or cancel these Terms at
              any time without prior notice.
            </p>
            <h2 class="title">Referral Program</h2>
            <p>
              Users who register on the Website, or the App, through an
              individual signup link provided by another user (”Referral Link”) are eligible to participate in
              the Referral Program and receive a variable reward ("Reward"). Users who refer new users via
              their Referral Link are eligible to receive a Reward per referred user that deposits at least
              $50 USD into their trading account. To receive the Reward, you must complete the registration
              procedure on the Website or the App.
            </p>
            <p>
              Each unique natural person can only receive a Reward for signing
              up through a Referral Link just once. We reserve the right to
              blacklist users if we believe they are not eligible or
              participated in botting/spamming or similar activities.
            </p>
            <h2 class="title">Account Level Limits</h2>
            <p>
              The number of Rewards a participant is able to claim is determined by their Account Level. The
              following limits apply:
            </p>
            <ul>
              <li>Novices can claim up to 10 Rewards.</li>
              <li>Investors can claim up to 50 Rewards.</li>
              <li>Moguls can claim an unlimited number of Rewards.</li>
            </ul>
            <p>
              After a Participant exhausts the Reward limits of their Account Level, the Referral Link can
              still be used by new users to sign up and be eligible for a Reward. The owner of the link will
              be able to claim already eligible Rewards after they have upgraded their Account Level.
            </p>
            <h2 class="title">Token Rewards and Reward Variability</h2>
            <p>
              The Reward amount is variable and is determined by chance. The
              minimum amount of the Reward is 10 MPH. The highest amount a
              Participant can receive in a single instalment is 100,000 MPH.
            </p>
            <p>
              The Rewards will be distributed according to the following
              structure:
            </p>
            <table class="tg">
              <thead>
                <tr>
                  <th>Probability</th>
                  <th>Payout (MPH)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>50.000%</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>25.000%</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>12.500%</td>
                  <td>50</td>
                </tr>
                <tr>
                  <td>10.000%</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>1.839%</td>
                  <td>500</td>
                </tr>
                <tr>
                  <td>0.500%</td>
                  <td>1,000</td>
                </tr>
                <tr>
                  <td>0.100%</td>
                  <td>5,000</td>
                </tr>
                <tr>
                  <td>0.050%</td>
                  <td>10,000</td>
                </tr>
                <tr>
                  <td>0.010%</td>
                  <td>50,000</td>
                </tr>
                <tr>
                  <td>0.001%</td>
                  <td>100,000</td>
                </tr>
              </tbody>
            </table>
            <p>
              Morpher Labs cannot be held accountable for software glitches that
              may impact the distribution of rewards. By participating in the
              referral program, users acknowledge and accept this limitation of
              liability in the event of any discrepancies or variations in
              reward distribution due to technical issues.
            </p>
            <p>
              Once the total reward amount has reached 100,000 MPH, the user are
              no longer eligible for further participation in the Referral
              Program. The user is still entitled to the previously claimed
              rewards and can continue to refer new users, but they will not
              receive further rewards.
            </p>
            <h2 class="title">Token Redemption</h2>
            <p>
              Morpher Labs will enable redemption of Morpher Token rewards after
              the Tokens have been created. There is nothing physical being
              provided, the Tokens are distributed electronically only.
              Redeeming claimed tokens will require registering on the App by
              connecting an Ethereum wallet. Failure to redeem Morpher tokens
              over an extended period of time, may lead to those Tokens being
              reallocated.
            </p>
            <h2 class="title">Token Value</h2>
            <p>
              Any price associated with Morpher Tokens or mentioned on the
              Website is our best estimate of value at the time. Morpher Labs
              does not take any financial responsibility for the value of
              Morpher Tokens. Morpher Labs does not guarantee the price of
              Morpher Tokens, or the ability to convert/exchange tokens into
              other forms of currency.
            </p>
            <!-- <h2 class="title">Legacy Users</h2>
						<p>
							Users that signed up prior to
							{{ endDateFormat() }}
							(”Legacy Users”) are eligible to earn rewards for referring new
							users if they had referred less than 100 users until the start of
							the Program. In that case, they are eligible for 100 rewards minus
							the number of their previous referral bonuses. If a Legacy User
							has referred 100 new users or more, other users will be able to
							use their Referral Link to register, but the referrer is no longer
							eligible to participate in the Referral Program.
						</p>
						<p>
							If a Legacy User has unclaimed bonuses, they will become invalid
							as of
							{{ endDateFormat() }}.
						</p> -->
            <h2 class="title">Eligibility</h2>
            <p>
              In order to be eligible for participating in the Referral Program,
              users must:
            </p>
            <ul>
              <li>Be at least 18 years of age</li>
              <li>Register on the website or in the app</li>
              <li>
                Not be a resident or "person" of the following countries: United
                States, Afghanistan, American Samoa, Democratic People's
                Republic of Korea, Guam, Iran, Iraq, Libya, Northern Mariana
                Islands, Puerto Rico, Samoa, Syria, US Virgin Islands, Yemen.
              </li>
              <li>Have signed up using a valid referral link (in order to claim registration bonus).</li>
            </ul>
            <p>
              A country may be added or removed from the list of supported
              countries at any time for any duration. This is set at our
              discretion.
            </p>
            <h2 class="title">Choice of Law and Jurisdiction</h2>
            <p>
              These Terms and any non-contractual obligations arising out of or
              in relation to it shall be governed by and construed in all
              respects in accordance with Austrian law, excluding the Austrian
              conflict of law rules of Austrian international private law.
            </p>
            <p>
              The Commercial Court for the first district of Vienna, Inner City
              (Austria) shall have exclusive jurisdiction to resolve disputes
              arising out of or in connection with these Terms.
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer :disclaimerContent="1" />
  </div>
</template>

<script>
import Navbar from "@/components/partials/Navbar";
import Footer from "@/components/partials/Footer";

export default {
	name: "ReferralTerms",
	components: {
		Navbar,
		Footer,
	},
	head() {
		return {
			title: {
				inner: "Referral Terms & Conditions",
			},
			meta: [
				{
					name: "description",
					content: "Terms and Conditions for the Morpher Crypto Airdrop.",
					id: "desc",
				},
				{
					property: "og:title",
					content: "Referral Terms & Conditions",
					id: "og-title",
				},
				{
					property: "og:description",
					content: "Terms and Conditions for the Morpher Referral Program.",
					id: "og-description",
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	mounted() {
		// $crisp.push(["do", "chat:hide"]);
		document.dispatchEvent(new Event("x-app-rendered"));
	},
	methods: {
		endDateFormat() {
			const dateString = "2023-02-23";
			const endDate = new Date(`${dateString} UTC`);
			return endDate.toLocaleDateString();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
	margin-left: 20px;
	margin-bottom: 8px;
}

ul li {
	margin-bottom: 4px;
}

.blog-container-column {
	max-width: 800px;
}

.blog-container-column .title:not(.title-font) {
	margin-top: 1.75rem;
	margin-bottom: 0.5rem;
	font-size: 1.25rem;
}

.blog-container-column p {
	margin-bottom: 0.75rem;
	font-size: 1.05rem;
}

.blog-container-column ul {
	list-style-type: circle;
}

/* Table Styling */
.tg {
	border-collapse: collapse;
	border-color: #ccc;
	border-spacing: 0;
	margin-bottom: 12px;
}

.tg td {
	background-color: #fff;
	border-color: #ccc;
	border-style: solid;
	border-width: 1px;
	color: #333;
	overflow: hidden;
	padding: 10px 5px;
	min-width: 150px;
	word-break: normal;
	font-size: 14px;
}

.tg th {
	background-color: #f0f0f0;
	border-color: #ccc;
	border-style: solid;
	border-width: 1px;
	color: #333;
	overflow: hidden;
	padding: 10px 5px;
	word-break: normal;
}

.tg th {
	background-color: #efefef;
	border-color: #f0f0f0;
	font-weight: bold;
	text-align: left;
	vertical-align: top;
}

.tg td {
	border-color: #f0f0f0;
	text-align: left;
	vertical-align: top;
}
</style>
