<template>
  <div>


    <!-- Body -->
    <section class="section">
      
      <div class="container">
        <div class="columns is-centered">
          <div class="column blog-container-column">
            <div class="kyc-content">
              <div>
                <img src="@/assets/img/kyc_header.svg" :alt="$t('rewards_how3_h3') + ' icon'">
              </div>

              <div v-if="status == 'SUCCESS'" class="kyc-update-header">Verification Submitted Successfully</div>
              <div v-else class="kyc-update-header-fail">Verification Failed</div>

              <h2 class="title-font" style="margin-bottom: 20px;">Redirecting back to app..</h2>

              <a :href="urlLink">Click here if you are not automatically redirected</a>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
	name: "SecurityBounty",
	components: {
	},
	data() {
		return {
			urlLink: '',
      		status: this.$route.params && this.$route.params.status ? this.$route.params.status.toUpperCase() : 'SUCCESS',
			headParams: {
        
				title: this.$t('meta.BOUNTY_TITLE'),
				description: this.$t('meta.BOUNTY_DESC'),
			},
		};
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: 'og-type' },
				{ property: "og:title", content: this.headParams.title, id: 'og-title' },
				{ property: "og:description", content: this.headParams.description, id: 'og-description' },
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_wall_logo.png",
          id: 'og-image'
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
          id: 'og-url'
				},
				{ property: "twitter:card", content: "summary_large_image", id: 'twitter-card' },
				{ property: "twitter:site", content: "@morpher_io", id: 'twitter-site' },
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_wall_logo.png",
          id: 'twitter-image'
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	mounted() {
		document.dispatchEvent(new Event("x-app-rendered"));

    	let urlLink = process.env.VUE_APP_MOBILE_LINK || 'https://morph.dynalinks.app{path}'
		
		if (this.status !== 'SUCCESS') {
			urlLink = urlLink.replace('{path}', '/kyc_failed')
		} else {
			urlLink = urlLink.replace('{path}', '/kyc_success')
		}
    	//window.location.href = urlLink;

		const os = this.getMobileOperatingSystem()

		if (os !== 'unknown') {
			window.location.href = process.env.VUE_APP_MOBILE_DEEPLINK + 'kyc/' + this.status;
		} else {
			window.location.href = 'https://morpher.com/trade/settings?kyc_status=' + (this.status ? this.status.toLowerCase() : '');
		}

		this.urlLink = urlLink;

	},
	methods: {
		launchEmail() {
			window.open("mailto:security@morpher.com", "_self");
		},
		getMobileOperatingSystem() {
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;
			if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
			{
				return 'ios';
			}
			else if( userAgent.match( /Android/i ) )
			{
				return 'android';
			}
			else
			{
				return 'unknown';
			}
		}
	},
};
</script>

<style scoped>
.section {
  background: #FFF9E3;
  height: 100vh;
  overflow: hidden;

}
.kyc-content {
  text-align: center;
}
.kyc-update-header {
  font-size: 30px;
  color: #039954;
;
}
.kyc-update-header-fail {
  font-size: 30px;
  color: #D70228;

}

</style>
