<template>
  <section>
    <Navbar />
    <div class="container section markdown-section">
      <h1 class="title" v-text="$t('CHANGELOG_TITLE')" />
      <!-- <p>
				{{ $t("CHANGELOG_FEATURE") }}
				<a
					href="https://discord.gg/CBxuAhPSCj"
					target="_blank"
					rel="nofollow noopener noreferrer"
					class="transition-faster"
				>
					{{ $t("CHANGELOG_DISCORD") }}
					<b-icon icon="fab fa-discord" pack="fab" size="is-small" />
				</a>
			</p> -->
      <div
        v-for="(changelog, index) in changelogs"
        :key="index"
        class="markdown"
      >
        <h1 class="title">
          {{ changelog.date.format("LL") }} - {{ changelog.title }}
        </h1>
        <div class="group">
          <div
            v-if="changelog.main"
            class="main"
            v-html="marked.parse(changelog.main)"
          />
          <div class="side">
            <div v-if="changelog.side" v-html="marked.parse(changelog.side)" />
            <div v-else />
          </div>
        </div>
        <div v-show="index !== changelogs.length - 1" class="divider" />
      </div>
    </div>
    <Footer :disclaimerContent="0" />
  </section>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue";
import Footer from "@/components/partials/Footer";
import { marked } from "marked";
import moment from "moment";

export default {
	name: "Changelog",
	components: {
		Navbar,
		Footer,
	},
	data() {
		return {
			changelogs: [],
			marked,
		};
	},
	head() {
		return {
			title: {
				inner: this.$t("meta.CHANGELOG"),
			},
			meta: [
				{
					name: "description",
					content: this.$t("meta.CHANGELOG_DESC"),
					id: "desc",
				},
				{ property: "og:type", content: "website", id: "og-type" },
				{
					property: "og:title",
					content: this.$t("meta.CHANGELOG"),
					id: "og-title",
				},
				{
					property: "og:description",
					content: this.$t("meta.CHANGELOG_DESC"),
					id: "og-description",
				},
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_whats_new_fb.png",
					id: "og-image",
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
					id: "og-url",
				},
				{
					property: "twitter:card",
					content: "summary_large_image",
					id: "twitter-card",
				},
				{
					property: "twitter:site",
					content: "@morpher_io",
					id: "twitter-site",
				},
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_whats_new_tw.png",
					id: "twitter-image",
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	async created() {
		const changelogs = [];
		const changelogFiles = require.context(
			"../changelogs/",
			true,
			/\.md$/i,
			"lazy"
		);

		let keys = [];

		changelogFiles.keys().forEach((key) => {
			let fileName = key.split("/").pop().split(".")[0];
			fileName = fileName.split("_")[0] + "_" + fileName.split("_")[1];

			const exist = keys.findIndex((key) => key.key === fileName);

			if (exist < 0) {
				keys.push({
					hasSide: changelogFiles.keys().includes("./" + fileName + "_side.md"),
					hasMain: changelogFiles.keys().includes("./" + fileName + "_main.md"),
					key: fileName,
					title: fileName.split("_")[0].replace(/-/g, " "),
					date: moment(fileName.split("_")[1], "DD-MM-YYYY"),
				});
			}
		});

		keys = keys.sort((a, b) => b.date.valueOf() - a.date.valueOf());

		for (const file of keys) {
			let mainFile;
			let sideFile;

			if (file.hasMain)
				mainFile = (await import("../changelogs/" + file.key + "_main.md"))
					.default;
			if (file.hasSide)
				sideFile = (await import("../changelogs/" + file.key + "_side.md"))
					.default;

			changelogs.push({
				main: mainFile,
				side: sideFile,
				title: file.title,
				date: file.date,
			});
		}

		this.changelogs = changelogs;
	},
	mounted() {
		document.dispatchEvent(new Event("x-app-rendered"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.markdown {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: revert;
		font-weight: revert;
	}

	html,
	body,
	p,
	ol,
	ul,
	li,
	dl,
	dt,
	dd,
	blockquote,
	figure,
	fieldset,
	legend,
	textarea,
	pre,
	iframe,
	hr,
	h1,
	h2 {
		margin: revert;
		padding: revert;
	}

	.title {
		font-size: 26px;
		margin-bottom: 0.75rem;
	}

	ul,
	ol {
		margin-top: 0;
		list-style: inherit;
		padding-left: 1rem;
	}

	.group {
		display: flex;

		p {
			margin-top: 0;
		}

		h1,
		h2 {
			margin-bottom: 0.25rem;
			margin-top: 0.25rem;
		}

		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 0;
		}

		.main {
			flex: 1;
		}

		.side {
			margin-left: 30px;
			flex: 1;
		}
	}

	.divider {
		width: 100%;
		height: 1px;
		background: rgb(228, 227, 226);
		margin-top: 1rem;
		margin-bottom: 2rem;
	}

	.green-highlight {
		background: #e5f9f3;
		border-radius: 10px;
		padding: 1.25rem;
		margin-top: 1rem;
	}
}

@media screen and (max-width: 768px) {
	.markdown .group {
		display: block;

		.side {
			margin: 0;
		}
	}

	.markdown-section {
		padding: 1.5rem;
	}
}
</style>
