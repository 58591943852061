<template>
  <section class="section is-paddingless">
    <Navbar />
    <br><br>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-9">
          <h2 class="my-5 title has-text-centered">Cookie Policy</h2>
          <strong>
            <span>Last updated October 24, 2019</span>
          </strong>
          <br>
          <div class="level level-left level-item">
            Cookie Consent Status: {{ cookieConsent ? "Accepted" : "Rejected" }}
            <button
              class="button"
              style="marginLeft: 20px"
              :disabled="!cookieConsent"
              @click="rejectCookies()"
            >
              Revoke Cookie Access
            </button>
          </div>
          <div class="mt-3">
            <span>
              This Cookie Policy explains how Morpher Labs GmbH uses cookies and
              similar technologies to recognize you when you visit our websites
              at https://www.morpher.com, ("
              <strong>Websites</strong>"). It explains what these technologies
              are and why we use them, as well as your rights to control our use
              of them.
            </span>
						&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              In some cases we may use cookies to collect personal information,
              or that becomes personal information if we combine it with other
              information. In such cases our Privacy Policy (
              <a href="https://www.morpher.com/privacy">https://www.morpher.com/privacy</a>) will apply in addition to this Cookie Policy.&nbsp;
            </span>
						&nbsp;
          </div>
          <br>
          <div>
            <strong>
              <span data-custom-class="heading_1">What are cookies?</span>
            </strong>
						&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              Cookies are small data files that are placed on your computer or
              mobile device when you visit a website. Cookies are widely used by
              website owners in order to make their websites work, or to work
              more efficiently, as well as to provide reporting information.
            </span>
						&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              Cookies set by the website owner (in this case, Morpher Labs GmbH)
              are called "first party cookies". Cookies set by parties other
              than the website owner are called "third party cookies". Third
              party cookies enable third party features or functionality to be
              provided on or through the website (e.g. like advertising,
              interactive content and analytics). The parties that set these
              third party cookies can recognize your computer both when it
              visits the website in question and also when it visits certain
              other websites.
            </span>
						&nbsp;
          </div>
          <br>
          <div>
            <strong>
              <span data-custom-class="heading_1">Why do we use cookies?</span>
            </strong>
						&nbsp;&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              We use first and third party cookies for several reasons. Some
              cookies are required for technical reasons in order for our
              Websites to operate, and we refer to these as "essential" or
              "strictly necessary" cookies. Other cookies also enable us to
              track and target the interests of our users to enhance the
              experience on our Online Properties. Third parties serve cookies
              through our Websites for advertising, analytics and other
              purposes. This is described in more detail below.
            </span>
						&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              The specific types of first and third party cookies served through
              our Websites and the purposes they perform are described below
              (please note that the specific cookies served may vary depending
              on the specific Online Properties you visit):&nbsp;
            </span>
						&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="heading_2">
              <strong> <u>Essential website cookies:</u>&nbsp; </strong>
							&nbsp;
            </span>
          </div>
          <div>
            <span data-custom-class="body_text">
              These cookies are strictly necessary to provide you with services
              available through our Websites and to use some of its features,
              such as access to secure areas.
            </span>
						&nbsp;
          </div>
          <br>
          <ul>
            <li>
              <span data-custom-class="body_text">
                <strong>Who serves these cookies:</strong>
              </span>
							&nbsp;
              <ul>
                <li>
                  <span data-custom-class="body_text">Cookie provider: Morpher &nbsp;</span>
                  <p>
                    <span data-custom-class="body_text">Website URL: https://www.morpher.com &nbsp;&nbsp;</span>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <span data-custom-class="body_text">
                <strong>How to refuse:</strong>&nbsp;Because these cookies are
                strictly necessary to deliver the Websites to you, you cannot
                refuse them. You can block or delete them by changing your
                browser settings however, as described below under the heading "
                <a href="#control" data-custom-class="link">How can I control cookies?</a>
                ".&nbsp;
              </span>
            </li>
          </ul>
          <br>
          <p>
            <span data-custom-class="heading_2">
              <strong>
                <u>Performance and functionality cookies:</u>&nbsp;
              </strong>
							&nbsp;&nbsp;
            </span>
          </p>
          <p>
            <span data-custom-class="body_text">
              These cookies are used to enhance the performance and
              functionality of our Websites but are non-essential to their use.
              However, without these cookies, certain functionality (like
              videos) may become unavailable.
            </span>
          </p>
          <br>
          <ul>
            <li>
              <span data-custom-class="body_text">
                <strong>Who serves these cookies:</strong>&nbsp;
              </span>
							&nbsp;
            </li>
            <ul>
              <li>
                <span data-custom-class="body_text">Cookie provider:&nbsp;Google Analytics &nbsp;&nbsp;</span>
              </li>
              <p>
                <span data-custom-class="body_text">Website URL: https://analytics.google.com/ &nbsp;</span>
								&nbsp;
              </p>
            </ul>
            <ul>
              <li>
                <span data-custom-class="body_text">Cookie provider:&nbsp;FullStory &nbsp;&nbsp;</span>
              </li>
              <p>
                <span data-custom-class="body_text">Website URL: https://www.fullstory.com/ &nbsp;</span>
								&nbsp;
              </p>
            </ul>
            <br>
            <li>
              <span data-custom-class="body_text">
                <strong>How to refuse:</strong>&nbsp;To refuse these cookies,
                please follow the instructions below under the heading "
                <a href="#control" data-custom-class="link">How can I control cookies?</a>
                ". Alternatively, please visit the relevant opt-out link below:
                &nbsp;
              </span>
            </li>
            <ul>
              <li>
                <span data-custom-class="body_text">Cookie provider: Google Analytics&nbsp;</span>
                <p>
                  <span data-custom-class="body_text">Opt-out Link:
                    https://tools.google.com/dlpage/gaoptout</span>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <span data-custom-class="body_text">Cookie provider: FullStory&nbsp;</span>
                <p>
                  <span data-custom-class="body_text">Opt-out Link: https://www.fullstory.com/optout/</span>
                </p>
              </li>
            </ul>
          </ul>
          <br>
          <p data-custom-class="heading_2">
            <strong> <u>Analytics and customization cookies:</u>&nbsp; </strong>
						&nbsp;
          </p>
          <p>
            <span data-custom-class="body_text">
              These cookies collect information that is used either in aggregate
              form to help us understand how our Website are being used or how
              effective our marketing campaigns are, or to help us customize our
              Websites for you.
            </span>
						&nbsp;
          </p>
          <br>
          <ul>
            <li>
              <span data-custom-class="body_text">
                <strong>Who serves these cookies:</strong>&nbsp;
              </span>
              <ul>
                <li>
                  <span data-custom-class="body_text">Cookie provider: Google Analytics &nbsp;&nbsp;</span>
                  <p>
                    <span data-custom-class="body_text">Website URL: https://analytics.google.com/</span>
                  </p>
                </li>
                <li>
                  <span data-custom-class="body_text">Cookie provider: FullStory &nbsp;&nbsp;</span>
                  <p>
                    <span data-custom-class="body_text">Website URL: https://www.fullstory.com/</span>
                  </p>
                </li>
              </ul>
            </li>
            <br>
            <li>
              <span data-custom-class="body_text">
                <strong>How to refuse:</strong> To refuse these cookies, please
                follow the instructions below under the heading "
              </span>
              <span data-custom-class="body_text">
                <a href="#control" data-custom-class="link">How can I control cookies?</a>
                ". Alternatively, please visit the relevant opt-out link below:
                &nbsp;
              </span>
            </li>
            <ul>
              <li>
                <span data-custom-class="body_text">Cookie provider: Google Analytics &nbsp;&nbsp;</span>
                <p>
                  <span data-custom-class="body_text">Opt-out Link:
                    https://tools.google.com/dlpage/gaoptout</span>
                </p>
              </li>
              <li>
                <span data-custom-class="body_text">Cookie provider: FullStory &nbsp;&nbsp;</span>
                <p>
                  <span data-custom-class="body_text">Opt-out Link: https://www.fullstory.com/optout/</span>
                </p>
              </li>
            </ul>
          </ul>
          <br>
          <p>
            <span data-custom-class="heading_2">
              <strong> <u>Advertising cookies:</u>&nbsp; </strong>
							&nbsp;
            </span>
          </p>
          <p>
            <span data-custom-class="body_text">
              These cookies are used to make advertising messages more relevant
              to you. They perform functions like preventing the same ad from
              continuously reappearing, ensuring that ads are properly displayed
              for advertisers, and in some cases selecting advertisements that
              are based on your interests.
            </span>
						&nbsp;
          </p>
          <br>
          <ul>
            <li>
              <span data-custom-class="body_text">
                <strong>Who serves these cookies:</strong>
              </span>
							&nbsp;
              <ul>
                <li>
                  <span data-custom-class="body_text">Cookie provider: Quora &nbsp;</span>
                  <p>
                    <span data-custom-class="body_text">Website URL: https://www.quora.com/ &nbsp;&nbsp;</span>
                  </p>
                </li>
                <li>
                  <span data-custom-class="body_text">Cookie provider: Google Ads &nbsp;</span>
                  <p>
                    <span data-custom-class="body_text">Website URL: https://ads.google.com/ &nbsp;&nbsp;</span>
                  </p>
                </li>
              </ul>
            </li>
            <br>
            <li>
              <span data-custom-class="body_text">
                <strong>How to refuse:</strong>&nbsp;To refuse these cookies,
                please follow the instructions below under the heading "
                <a href="#control" data-custom-class="link">How can I control cookies?</a>
                ".
                <span data-custom-class="body_text">&nbsp;</span> &nbsp;
              </span>
            </li>
            <li>
              <span data-custom-class="body_text">Cookie provider: Quora &nbsp;&nbsp;</span>
              <p>
                <span data-custom-class="body_text">Opt-out Link: https://www.quora.com/optout/</span>
              </p>
            </li>
          </ul>
          <br>
          <p>
            <span data-custom-class="heading_2">
              <strong>
                <u>Social networking cookies:</u>
              </strong>
            </span>
          </p>
          <p>
            <span data-custom-class="body_text">
              These cookies are used to enable you to share pages and content
              that you find interesting on our Websites through third party
              social networking and other websites. These cookies may also be
              used for advertising purposes too.
            </span>
						&nbsp;
          </p>
          <br>
          <ul>
            <li>
              <span data-custom-class="body_text">
                <strong>Who serves these cookies:</strong>
              </span>
							&nbsp;
              <ul>
                <li>
                  <span data-custom-class="body_text">Cookie provider: Quora &nbsp;</span>
                  <p>
                    <span data-custom-class="body_text">Website URL: https://www.quora.com/ &nbsp;&nbsp;</span>
                  </p>
                </li>
              </ul>
            </li>
            <br>
            <li>
              <span data-custom-class="body_text">
                <strong>How to refuse:</strong>&nbsp;To refuse these cookies,
                please follow the instructions below under the heading "
                <a href="#control" data-custom-class="link">How can I control cookies?</a>
                ".
                <span data-custom-class="body_text">&nbsp;</span> &nbsp;
              </span>
            </li>
            <li>
              <span data-custom-class="body_text">Cookie provider: Quora &nbsp;&nbsp;</span>
              <p>
                <span data-custom-class="body_text">Opt-out Link: https://www.quora.com/optout/</span>
              </p>
            </li>
          </ul>
          <br>
          <div>
            <strong>
              <span data-custom-class="heading_1">What about other tracking technologies, like web beacons?</span>
            </strong>
						&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">Cookies are not the only way&nbsp;</span>
            <span data-custom-class="body_text">
              to recognize or track visitors to a website. We may use other,
              similar technologies from time to time, like web beacons
              (sometimes called "tracking pixels" or "clear gifs"). These are
              tiny graphics files that contain a unique identifier that enable
              us to recognize when someone has visited our Websites or opened an
              e-mail that we have sent them. This allows us, for example, to
              monitor&nbsp;
            </span>
            <span data-custom-class="body_text">
              the traffic patterns of users from one page within our Websites to
              another, to deliver or communicate with cookies, to understand
              whether you have come to our Websites from an online advertisement
              displayed on a third-party website, to improve site performance,
              and to measure the success of e-mail marketing campaigns. In many
              instances, these technologies are reliant on cookies to function
              properly, and so declining cookies will impair their functioning.
            </span>
						&nbsp;&nbsp;&nbsp;
          </div>
          <br>
          <div>
            <strong>
              <span data-custom-class="heading_1">Do you serve targeted advertising?</span>
            </strong>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              Third parties may serve cookies on your computer or mobile device
              to serve advertising through our Websites. These companies may use
              information about your visits to this and other websites in order
              to provide relevant advertisements about goods and services that
              you may be interested in. They may also employ technology that is
              used to measure the effectiveness of advertisements. This can be
              accomplished by them using cookies or web beacons to collect
              information about your visits to this and other sites in order to
              provide relevant advertisements about goods and services of
              potential interest to you. The information collected through this
              process does not enable us or them to identify your name, contact
              details or other details that directly identify you unless you
              choose to provide these.
            </span>
						&nbsp;&nbsp;&nbsp;
          </div>
          <br>
          <div>
            <span id="control">
              <strong>
                <span data-custom-class="heading_1">How can I control cookies?</span>
              </strong>
							&nbsp;
            </span>
						&nbsp;&nbsp;&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              You can set or amend your web browser controls to accept or refuse
              cookies. If you choose to reject cookies, you may still use our
              website though your access to some functionality and areas of our
              website may be restricted. As the means by which you can refuse
              cookies through your web browser controls vary from
              browser-to-browser, you should visit your browser's help menu for
              more information.
            </span>
						&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              In addition, most advertising networks offer you a way to opt out
              of targeted advertising. If you would like to find out more
              information, please visit&nbsp;
            </span>
            <span data-custom-class="body_text">
              <a
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noopener"
                data-custom-class="link"
              >http://www.aboutads.info/choices/</a>
            </span>
            <span data-custom-class="body_text">&nbsp;or&nbsp;</span>
            <span data-custom-class="body_text">
              <a
                href="http://www.youronlinechoices.com"
                target="_blank"
                rel="noopener"
                data-custom-class="link"
                data-fr-linked="true"
              >http://www.youronlinechoices.com</a>
            </span>
            <span data-custom-class="body_text">.</span>&nbsp;&nbsp;
          </div>
          <br>
          <div>
            <strong>
              <span data-custom-class="heading_1">How often will you update this Cookie Policy?</span>
            </strong>
						&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">We may update&nbsp;</span>
            <span data-custom-class="body_text">
              this Cookie Policy from time to time in order to reflect, for
              example, changes to the cookies we use or for other operational,
              legal or regulatory reasons. Please therefore re-visit this Cookie
              Policy regularly to stay informed about our use of cookies and
              related technologies.
            </span>
						&nbsp;&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              The date at the top of this Cookie Policy indicates when it was
              last updated.
            </span>
						&nbsp;&nbsp;
          </div>
          <br>
          <div>
            <strong>
              <span data-custom-class="heading_1">Where can I get further information?</span>
            </strong>
						&nbsp;&nbsp;
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">
              If you have any questions about our use of cookies or other
              technologies, please contact us at:
            </span>
          </div>
          <br>
          <div>
            <span data-custom-class="body_text">Morpher Labs GmbH</span>
          </div>
          <div>
            <span data-custom-class="body_text">Heinestraße 21/4</span>
          </div>
          <div>
            <span data-custom-class="body_text">Vienna, 1020</span>
          </div>
          <div>
            <span data-custom-class="body_text">Austria</span>
          </div>
          <div>
            <span data-custom-class="body_text">
              <a href="mailto:privacy@morpher.com">privacy@morpher.com</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <br>
    <Footer :disclaimerContent="0" />
  </section>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue";
import Footer from "@/components/partials/Footer";
import * as Cookies from "js-cookie";

export default {
	name: "CookiePolicy",
	components: {
		Navbar,
		Footer,
	},
	data() {
		return {
			cookieConsent: false,
		};
	},
	head() {
		return {
			title: {
				inner: this.$t('meta.COOKIE_POLICY'),
			},
			meta: [
				{
					name: "description",
					content: this.$t('meta.COOKIE_POLICY_DESC'),
					id: "desc",
				},
				{ property: "og:title", content: this.$t('meta.COOKIE_POLICY'), id: 'og-title' },
				{ property: "og:description", content: this.$t('meta.COOKIE_POLICY_DESC'), id: 'og-description' },
				{ property: "og:image", content: "", id: 'og-image' },
				{ property: "twitter:card", content: "", id: 'twitter-card' },
				{ property: "twitter:image", content: "", id: 'twitter-image' },
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	mounted() {
		// $crisp.push(["do", "chat:hide"]);
		document.dispatchEvent(new Event("x-app-rendered"));
		if (Cookies.get("cookieConsent") === "true") {
			this.cookieConsent = true;
		}
	},
	methods: {
		// Revoke injected cookies if user no longer wants to give consent
		rejectCookies() {
      try {
	      if (this.$gtag) {
					this.$gtag.set('allow_google_signals', false);
        }
      } catch {}      
			Cookies.remove("cookieConsent");
			window.location.reload();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
