<template>
  <div>
    <Hero
      :referredByName="''"
      :referredBy="''"
      :airdropAmount="airdropAmount"
      :referralBonus="referralBonus"
      :customInvite="customInvite"
      :inviteMessage="inviteMessage"
    />
    <HowItWorks :customInvite="customInvite" />
    <Footer
      :disclaimerContent="2"
      :emptyFooter="customInvite !== ''"
      :airdropPage="true"
      :customInvite="customInvite"
    />
  </div>
</template>

<script>
import Hero from "@/components/airdrop/Hero";
import HowItWorks from "@/components/airdrop/HowItWorks";
import Footer from "@/components/partials/Footer";

export default {
  name: "CustomInvite",
  components: {
    Hero,
    HowItWorks,
    Footer,
  },
  props: {
    airdropAmount: Number,
    referralBonus: Number,
    customInvite: String,
    inviteMessage: String,
  },
  data() {
		return {
			headParams: {
				title: this.$t("meta.AIRDROP_TITLE"),
				description: this.$t("meta.AIRDROP_DESC"),
			},
		}
	},
  head() {
    return {
      title: {
        inner: this.headParams.title,
      },
      meta: [
        {
          name: "description",
          content: this.headParams.description,
          id: "desc",
        },
        { property: "og:type", content: "website", id: "og-type" },
        {
          property: "og:title",
          content: this.headParams.title,
          id: "og-title",
        },
        {
          property: "og:description",
          content: this.headParams.description,
          id: "og-description",
        },
        {
          property: "og:image",
          content: "https://www.morpher.com/img/og_airdrop_fb.png",
          id: "og-image",
        },
        {
          property: "og:url",
          content: "https://www.morpher.com" + this.$route.path,
          id: "og-url",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
          id: "twitter-card",
        },
        {
          property: "twitter:site",
          content: "@morpher_io",
          id: "twitter-site",
        },
        {
          property: "twitter:image",
          content: "https://www.morpher.com/img/og_airdrop_tw.png",
          id: "twitter-image",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.morpher.com" + this.$route.path,
          id: "canonical",
        },
      ],
    };
  },
};
</script>
