<template>
  <div>
    <Navbar />
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column blog-container-column">
            <h1 class="title is-2 title-font">Morpher Token Airdrop FAQ</h1>
            <div class="content blog is-medium">
              <h2 class="title-font">How does the airdrop work?</h2>
              <p>
                We created the airdrop to give Morpher Tokens to as many people
                as possible. The goal is to give away at least 50% of all
                Morpher Tokens.
              </p>
              <p>
                The first 10,000 people to successfully register for the airdrop
                will get 2,000 tokens. Every user after that will get at most
                1,000 tokens with the amount decreasing with more sign ups.
                First users to sign up and pass KYC are also more likely to get
                earlier access to the investment app.
              </p>
              <p>To register for the airdrop you need to:</p>
              <ul>
                <li>Have a valid email</li>
                <li>Be over 18 years old</li>
                <li>Have valid ID to pass a KYC/AML check</li>
                <li>Live outside the United States</li>
                See
              </ul>
              <p>
                See
                <router-link to="/airdrop-terms-conditions">Terms & Conditions</router-link>
                <!-- prettier-ignore -->
                for full list of terms.
              </p>
              <h2 class="title-font">What are Morpher Tokens?</h2>
              <p>
                Morpher is built on the Ethereum blockchain, and Morpher Tokens
                are an Ethereum based cryptocurrency (an ERC20 token to be
                precise). This means Morpher Tokens are compatible with most
                crypto wallets and can be listed on all major exchanges.
              </p>
              <p>
                Morpher Tokens power our unique trading platform. They're used
                as the currency for investments. All trades are settled in
                Morpher Tokens instead of USD or Bitcoin.
              </p>
              <h2 class="title-font">Are Morpher Tokens worth anything?</h2>
              <p>
                Yes, Morpher Tokens are estimated at $0.03/token based on a
                private pre-sale. This price is subject to change once the
                tokens become listed on secondary crypto exchanges. Morpher
                Tokens will from then on be priced by trading activity on these
                exchanges. We can't guarantee the tokens will be worth $0.03 USD
                once you receive them in the airdrop, but that's our best
                estimate of their value before listing.
              </p>
              <h2 class="title-font">
                Why are you giving away free tokens? What's the catch?
              </h2>
              <p>
                Morpher Tokens are a crucial part of the Morpher platform, so we
                wanted to distribute them to people that are interested in using
                Morpher. Our mission is to democratize finance, and that's hard
                to accomplish if you put a price tag on entry. It's another way
                we're trying to remove barriers from trading and investing.
              </p>
              <h2 class="title-font">
                Can I sell Morpher Tokens for dollars/euros?
              </h2>
              <p>
                Yes, you will be able to convert Morpher Tokens into fiat
                currency on several cryptocurrency exchanges.
              </p>
              <h2 class="title-font">Why do I need to pass KYC/AML?</h2>
              <p>
                This is how we can guarantee that we have enough tokens to give
                out to real people instead of bots and spammers. It also helps
                us prevent signups from countries that we don't support yet.
              </p>
              <h2 class="title-font">Why can't I sign up from my country?</h2>
              <p>
                Not every region can be supported at launch, but we're working
                hard to expand global compliance. Morpher is based in Austria so
                we're focused on European regulations. We hope to support more
                global customers soon.
              </p>
              <h2 class="title-font">How can I earn more tokens?</h2>
              <p>
                You will get a custom link after signing up. For every friend
                that signs up using your link, we'll give you another 1,000
                tokens (the reward drops with more sign ups). There is no limit
                to how many friends you can refer, so there's no limit to how
                many tokens you can earn until supply runs out.
              </p>
              <br>
              <router-link to="/airdrop" class="title-font is-size-4">
                <b-icon
                  icon="fas fa-angle-double-left"
                  pack="fas"
                  size="is-small"
                />
                <!-- prettier-ignore -->
                Return to Airdrop
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer :disclaimerContent="1" />
  </div>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue";
import Footer from "@/components/partials/Footer";

export default {
	name: "AirdropFAQ",
	components: {
		Navbar,
		Footer,
	},
	data() {
		return {
			headParams: {
				title: this.$t('meta.FAQ_TITLE'),
				description: this.$t('meta.FAQ_DESC'),
			},
		};
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: 'og-type' },
				{ property: "og:title", content: this.headParams.title, id: 'og-title' },
				{ property: "og:description", content: this.headParams.description, id: 'og-description' },
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_airdrop_fb.png",
          id: 'og-image'
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
          id: 'og-url'
				},
				{ property: "twitter:card", content: "summary_large_image", id: 'twitter-card' },
				{ property: "twitter:site", content: "@morpher_io", id: 'twitter-site' },
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_airdrop_tw.png",
          id: 'twitter-image'
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	mounted() {
		document.dispatchEvent(new Event("x-app-rendered"));
	},
};
</script>

<style scoped>
.blog-container-column {
	max-width: 800px;
}
.blog {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.blog .title-font {
	font-size: 1.75rem;
}
</style>
