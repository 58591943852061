<template>
  <section>
    <Navbar />
    <br>
    <div class="container">
      <div class="columns">
        <div class="column">
          <h2 class="my-5 title has-text-centered">Impressum</h2>
          <div data-custom-class="subtitle">
            <strong>Last Updated June 18 2020</strong>
          </div>
          <br>
          <p>
            morpher.com is hosted by
            <br>Morpher Labs GmbH <br>Heinestraße 21/4 <br>Vienna, 1020
            <br>Austria
          </p>
          <br>
          <div>
            Email: privacy@morpher.com
            <br>
            <div
              class="hover-area is-inline-block"
              @mouseover="hover_phone = true"
              @mouseout="hover_phone = false"
            >
              <span v-if="!hover_phone">
                Phone:
                <span class="hover-text has-text-weight-medium">HOVER TO REVEAL</span>
              </span>
              <span v-else>Phone: {{ phone_num }}</span>
            </div>
            <br>Managing Director: Martin Fröhler <br>Handelsgericht Wien,
            FN 497734m <br>USt-ID-Nr: ATU74020445
          </div>
          <br>
          <br>
        </div>
      </div>
    </div>
    <br>
    <Footer :disclaimerContent="0" />
  </section>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue";
import Footer from "@/components/partials/Footer";

export default {
	name: "Disclaimer",
	components: {
		Navbar,
		Footer,
	},
	data() {
		return {
			hover_phone: false,
			phone_num: "+43 676 92 86 900",
		};
	},
	head() {
		return {
			title: {
				inner: this.$t("meta.IMPRESSUM_TITLE"),
			},
			meta: [
				{
					name: "description",
					content: this.$t("meta.IMPRESSUM_DESC"),
					id: "desc",
				},
				{ property: "og:title", content: this.$t("meta.IMPRESSUM_TITLE"), id: 'og-title' },
				{
					property: "og:description",
					content: this.$t("meta.IMPRESSUM_DESC"),
					id: 'og-description'
				},
				{ property: "og:image", content: "", id: 'og-image' },
				{ property: "twitter:card", content: "", id: 'twitter-card' },
				{ property: "twitter:image", content: "", id: 'twitter-image' },
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	mounted() {
		// $crisp.push(["do", "chat:hide"]);
		document.dispatchEvent(new Event("x-app-rendered"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hover-text {
	text-decoration: underline;
}
.hover-area {
	cursor: pointer;
}
</style>
