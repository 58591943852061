var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section is-paddingless"},[_c('Navbar'),_c('br'),_c('br'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-9"},[_c('h2',{staticClass:"my-5 title has-text-centered"},[_vm._v("Cookie Policy")]),_vm._m(0),_c('br'),_c('div',{staticClass:"level level-left level-item"},[_vm._v(" Cookie Consent Status: "+_vm._s(_vm.cookieConsent ? "Accepted" : "Rejected")+" "),_c('button',{staticClass:"button",staticStyle:{"marginLeft":"20px"},attrs:{"disabled":!_vm.cookieConsent},on:{"click":function($event){return _vm.rejectCookies()}}},[_vm._v(" Revoke Cookie Access ")])]),_vm._m(1),_c('br'),_vm._m(2),_c('br'),_vm._m(3),_c('br'),_vm._m(4),_c('br'),_vm._m(5),_c('br'),_vm._m(6),_c('br'),_vm._m(7),_c('br'),_vm._m(8),_c('br'),_vm._m(9),_vm._m(10),_c('br'),_vm._m(11),_c('br'),_vm._m(12),_vm._m(13),_c('br'),_vm._m(14),_c('br'),_vm._m(15),_vm._m(16),_c('br'),_vm._m(17),_c('br'),_vm._m(18),_vm._m(19),_c('br'),_vm._m(20),_c('br'),_vm._m(21),_vm._m(22),_c('br'),_vm._m(23),_c('br'),_vm._m(24),_c('br'),_vm._m(25),_c('br'),_vm._m(26),_c('br'),_vm._m(27),_c('br'),_vm._m(28),_c('br'),_vm._m(29),_c('br'),_vm._m(30),_c('br'),_vm._m(31),_c('br'),_vm._m(32),_c('br'),_vm._m(33),_c('br'),_vm._m(34),_c('br'),_vm._m(35),_c('br'),_vm._m(36),_vm._m(37),_vm._m(38),_vm._m(39),_vm._m(40)])])]),_c('br'),_c('Footer',{attrs:{"disclaimerContent":0}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('span',[_vm._v("Last updated October 24, 2019")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('span',[_vm._v(" This Cookie Policy explains how Morpher Labs GmbH uses cookies and similar technologies to recognize you when you visit our websites at https://www.morpher.com, (\" "),_c('strong',[_vm._v("Websites")]),_vm._v("\"). It explains what these technologies are and why we use them, as well as your rights to control our use of them. ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information. In such cases our Privacy Policy ( "),_c('a',{attrs:{"href":"https://www.morpher.com/privacy"}},[_vm._v("https://www.morpher.com/privacy")]),_vm._v(") will apply in addition to this Cookie Policy.  ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('strong',[_c('span',{attrs:{"data-custom-class":"heading_1"}},[_vm._v("What are cookies?")])]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information. ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" Cookies set by the website owner (in this case, Morpher Labs GmbH) are called \"first party cookies\". Cookies set by parties other than the website owner are called \"third party cookies\". Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites. ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('strong',[_c('span',{attrs:{"data-custom-class":"heading_1"}},[_vm._v("Why do we use cookies?")])]),_vm._v("    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" We use first and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites to operate, and we refer to these as \"essential\" or \"strictly necessary\" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Websites for advertising, analytics and other purposes. This is described in more detail below. ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" The specific types of first and third party cookies served through our Websites and the purposes they perform are described below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):  ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"heading_2"}},[_c('strong',[_c('u',[_vm._v("Essential website cookies:")]),_vm._v("  ")]),_vm._v("   ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to secure areas. ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("Who serves these cookies:")])]),_vm._v("   "),_c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Morpher  ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Website URL: https://www.morpher.com   ")])])])])]),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("How to refuse:")]),_vm._v(" Because these cookies are strictly necessary to deliver the Websites to you, you cannot refuse them. You can block or delete them by changing your browser settings however, as described below under the heading \" "),_c('a',{attrs:{"href":"#control","data-custom-class":"link"}},[_vm._v("How can I control cookies?")]),_vm._v(" \".  ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{attrs:{"data-custom-class":"heading_2"}},[_c('strong',[_c('u',[_vm._v("Performance and functionality cookies:")]),_vm._v("  ")]),_vm._v("    ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" These cookies are used to enhance the performance and functionality of our Websites but are non-essential to their use. However, without these cookies, certain functionality (like videos) may become unavailable. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("Who serves these cookies:")]),_vm._v("  ")]),_vm._v("   ")]),_c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Google Analytics   ")])]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Website URL: https://analytics.google.com/  ")]),_vm._v("   ")])]),_c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: FullStory   ")])]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Website URL: https://www.fullstory.com/  ")]),_vm._v("   ")])]),_c('br'),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("How to refuse:")]),_vm._v(" To refuse these cookies, please follow the instructions below under the heading \" "),_c('a',{attrs:{"href":"#control","data-custom-class":"link"}},[_vm._v("How can I control cookies?")]),_vm._v(" \". Alternatively, please visit the relevant opt-out link below:   ")])]),_c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Google Analytics ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Opt-out Link: https://tools.google.com/dlpage/gaoptout")])])])]),_c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: FullStory ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Opt-out Link: https://www.fullstory.com/optout/")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{attrs:{"data-custom-class":"heading_2"}},[_c('strong',[_c('u',[_vm._v("Analytics and customization cookies:")]),_vm._v("  ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" These cookies collect information that is used either in aggregate form to help us understand how our Website are being used or how effective our marketing campaigns are, or to help us customize our Websites for you. ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("Who serves these cookies:")]),_vm._v("  ")]),_c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Google Analytics   ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Website URL: https://analytics.google.com/")])])]),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: FullStory   ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Website URL: https://www.fullstory.com/")])])])])]),_c('br'),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("How to refuse:")]),_vm._v(" To refuse these cookies, please follow the instructions below under the heading \" ")]),_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('a',{attrs:{"href":"#control","data-custom-class":"link"}},[_vm._v("How can I control cookies?")]),_vm._v(" \". Alternatively, please visit the relevant opt-out link below:   ")])]),_c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Google Analytics   ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Opt-out Link: https://tools.google.com/dlpage/gaoptout")])])]),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: FullStory   ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Opt-out Link: https://www.fullstory.com/optout/")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{attrs:{"data-custom-class":"heading_2"}},[_c('strong',[_c('u',[_vm._v("Advertising cookies:")]),_vm._v("  ")]),_vm._v("   ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests. ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("Who serves these cookies:")])]),_vm._v("   "),_c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Quora  ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Website URL: https://www.quora.com/   ")])])]),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Google Ads  ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Website URL: https://ads.google.com/   ")])])])])]),_c('br'),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("How to refuse:")]),_vm._v(" To refuse these cookies, please follow the instructions below under the heading \" "),_c('a',{attrs:{"href":"#control","data-custom-class":"link"}},[_vm._v("How can I control cookies?")]),_vm._v(" \". "),_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" ")]),_vm._v("   ")])]),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Quora   ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Opt-out Link: https://www.quora.com/optout/")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{attrs:{"data-custom-class":"heading_2"}},[_c('strong',[_c('u',[_vm._v("Social networking cookies:")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" These cookies are used to enable you to share pages and content that you find interesting on our Websites through third party social networking and other websites. These cookies may also be used for advertising purposes too. ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("Who serves these cookies:")])]),_vm._v("   "),_c('ul',[_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Quora  ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Website URL: https://www.quora.com/   ")])])])])]),_c('br'),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('strong',[_vm._v("How to refuse:")]),_vm._v(" To refuse these cookies, please follow the instructions below under the heading \" "),_c('a',{attrs:{"href":"#control","data-custom-class":"link"}},[_vm._v("How can I control cookies?")]),_vm._v(" \". "),_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" ")]),_vm._v("   ")])]),_c('li',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookie provider: Quora   ")]),_c('p',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Opt-out Link: https://www.quora.com/optout/")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('strong',[_c('span',{attrs:{"data-custom-class":"heading_1"}},[_vm._v("What about other tracking technologies, like web beacons?")])]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Cookies are not the only way ")]),_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called \"tracking pixels\" or \"clear gifs\"). These are tiny graphics files that contain a unique identifier that enable us to recognize when someone has visited our Websites or opened an e-mail that we have sent them. This allows us, for example, to monitor  ")]),_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" the traffic patterns of users from one page within our Websites to another, to deliver or communicate with cookies, to understand whether you have come to our Websites from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of e-mail marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning. ")]),_vm._v("     ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('strong',[_c('span',{attrs:{"data-custom-class":"heading_1"}},[_vm._v("Do you serve targeted advertising?")])]),_vm._v("        ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" Third parties may serve cookies on your computer or mobile device to serve advertising through our Websites. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other details that directly identify you unless you choose to provide these. ")]),_vm._v("     ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"id":"control"}},[_c('strong',[_c('span',{attrs:{"data-custom-class":"heading_1"}},[_vm._v("How can I control cookies?")])]),_vm._v("   ")]),_vm._v("     ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information. ")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit  ")]),_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('a',{attrs:{"href":"http://www.aboutads.info/choices/","target":"_blank","rel":"noopener","data-custom-class":"link"}},[_vm._v("http://www.aboutads.info/choices/")])]),_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" or ")]),_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('a',{attrs:{"href":"http://www.youronlinechoices.com","target":"_blank","rel":"noopener","data-custom-class":"link","data-fr-linked":"true"}},[_vm._v("http://www.youronlinechoices.com")])]),_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(".")]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('strong',[_c('span',{attrs:{"data-custom-class":"heading_1"}},[_vm._v("How often will you update this Cookie Policy?")])]),_vm._v("   ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("We may update ")]),_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies. ")]),_vm._v("    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" The date at the top of this Cookie Policy indicates when it was last updated. ")]),_vm._v("    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('strong',[_c('span',{attrs:{"data-custom-class":"heading_1"}},[_vm._v("Where can I get further information?")])]),_vm._v("    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v(" If you have any questions about our use of cookies or other technologies, please contact us at: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Morpher Labs GmbH")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Heinestraße 21/4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Vienna, 1020")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_vm._v("Austria")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{attrs:{"data-custom-class":"body_text"}},[_c('a',{attrs:{"href":"mailto:privacy@morpher.com"}},[_vm._v("privacy@morpher.com")])])])
}]

export { render, staticRenderFns }