<template>
  <section class="section is-paddingless">
    <Navbar />

    <div class="container">
      <br><br><br>
      <br>
      <div class="has-text-centered">
        <h1 class="title is-1 is-spaced">404</h1>
        <h2 class="subtitle" v-text="$t('not_found_text')" />
        <a href="/"><button class="button is-primary is-outlined">{{$t('go_home')}}</button></a>
      </div>
    </div>
  </section>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue"

export default {
  name: "PageNotFound",
  components: {
    Navbar
  },
  head() {
    return {
      title: {
        inner: this.$t('meta.PAGE_NOT_FOUND')
      },
      meta: [
        { hid: "robots", name: "robots", content: "noindex" }
      ]
    };
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'));
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mt-10 {
  margin-top: 10px !important;
}
</style>
