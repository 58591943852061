import { render, staticRenderFns } from "./Investors.vue?vue&type=template&id=c362b2f2&scoped=true"
import script from "./Investors.vue?vue&type=script&lang=js"
export * from "./Investors.vue?vue&type=script&lang=js"
import style0 from "./Investors.vue?vue&type=style&index=0&id=c362b2f2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c362b2f2",
  null
  
)

export default component.exports