<template>
  <div>
    <!-- Hero Title -->
    <section class="hero is-medium">
      <Navbar />
      <div class="hero-body has-text-centered">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column">
              <h1 class="title is-2 title-font">
                Security & Bug Bounty Program
              </h1>
              <p class="subtitle is-size-4">
                Responsible Vulnerability Disclosure Policy
              </p>
              <!-- Contact Button -->
              <button
                class="cta-btn button is-primary mt-10"
                @click="launchEmail()"
              >
                Submit Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Body -->
    <section class="section" style="margin-top: -40px">
      <div class="container">
        <div class="columns is-centered">
          <div class="column blog-container-column">
            <div class="content blog is-medium">
              <span>Updated 08/01/2021</span>
              <h2 class="title-font">Overview</h2>
              <p>
                The safety of our customers' data, as well as the un-interrupted
                functionality of our platform is of the highest concerns to
                Morpher. Despite our regular audits, code reviews, and security
                checks, we are always happy to hear from the investigative work
                into security vulnerabilities carried out by well-intentioned,
                ethical security researchers.
              </p>
              <p>
                This page aims to define a methodology by which we, the Morpher
                Team, can work with you, the security researcher, to improve our
                online security.
              </p>
              <h2 class="title-font">Scope</h2>
              <p>
                We are interested in all security issues you find, and we're
                happy to provide a bounty for specific critical bugs (depending
                on the severity).
              </p>
              <h3 class="title-font is-size-4">What We're Looking For</h3>
              <p>
                We want to hear about every new (unreported) vulnerability that
                is considered “critical” and affects Morpher systems. Our
                systems include morpher.com, subdomains, and the
                <a
                  href="https://github.com/Morpher-io/MorpherProtocol"
                  target="_blank"
                >Morpher Protocol</a>. Examples of this include:
              </p>
              <ul>
                <li>
                  Data leaks & data tampering (especially involving user data)
                </li>
                <li>
                  Account take-overs or the ability to manipulate user accounts
                </li>
                <li>Disruption of services for all/some users</li>
                <li>
                  Manipulation or disruption of the Morpher Protocol including
                  trading, token operations, and admin functions.
                </li>
              </ul>
              <h3 class="title-font is-size-4">
                Non-Critical but Good to Know
              </h3>
              <p>
                There are also many "non-critical" vulnerabilities we still want
                to hear about if you discover them. Often this is when
                best-practices aren't being followed such as:
              </p>
              <ul>
                <li>
                  Weak Ciphers or any other TLS configuration weakness
                </li>
                <li>
                  Missing security headers (CSRF, etc.)
                </li>
                <li>Bad email configuration (SPF, DMARC, etc.)</li>
              </ul>
              <h3 class="title-font is-size-4">Out of Scope</h3>
              <p>
                We will generally not consider investigating things that are out
                of our control such as:
              </p>
              <ul>
                <li>Services hosted/run by 3rd parties</li>
                <li>
                  Any DoS or DDoS like vulnerabilities (overloading our systems
                  with a high load is simply a bad idea)
                </li>
                <li>
                  Social Engineering, phishing, or even physical attacks
                  <span class="has-text-weight-semibold">(don’t do that, ever, really!)</span>
                </li>
              </ul>
              <p>
                The following vulnerabilities are explicitly not included in the
                bug bounty program and will not be responded to:
              </p>
              <ul>
                <li>
                  Fortmatic and other third-party issues, such as rate limiting
                  vulnerabilities, session management misconfiguration, etc.
                </li>
                <p>
                  Reason: We are not in the position to address and further fix
                  those vulnerabilities, as they do not occur within our
                  services.
                </p>
                <li>
                  Infrastructure information disclosure, such as header leakage.
                </li>
                <p>
                  Reason: Infrastructure information leakage occurs through our
                  third party providers, such as AWS and others.
                </p>
                <li>
                  iFrame related issues, such as clickjacking from our website.
                </li>
              </ul>
              <p>
                Any bugs you experience in the app, that are not "critical",
                should be shared with
                <a href="mailto:contact@help.morpher.com">contact@help.morpher.com</a>
                instead.
              </p>
              <h2 class="title-font">Rewards</h2>
              <p>
                We absolutely believe that great security research should be
                rewarded. Our reward structure is fluid, there's no lower or
                upper limit. It strictly depends on the severity of the
                vulnerability.
                <span class="has-text-weight-semibold">
                  More dangerous bugs command greater gratitude and higher
                  rewards.
                </span>
              </p>
              <h2 class="title-font">Report</h2>
              <p>
                If you discover a vulnerability in our systems, please email
                <a href="mailto:security@morpher.com">security@morpher.com</a>.
              </p>
              <p>
                Please include as much detail as possible. Depending on the
                complexity, it would be helpful to understand how to reproduce
                it, and what systems are involved. A quick guide or screencast
                is recommended.
              </p>
              <p>
                If you report a vulnerability that our team is not yet aware of,
                we’ll evaluate the severity and inform you within 1 week if it's
                eligible for a bug bounty.
              </p>
              <h2 class="title-font">Guidance & Qualifications</h2>
              <p>
                Below are our requirements for how proper security research and
                reporting should be conducted:
              </p>
              <ul>
                <li>
                  Avoid accessing unnecessary amounts of data or interrupting
                  our services, if not necessary to prove the existence of a
                  vulnerability.
                </li>
                <li>
                  Do not communicate or disclose the vulnerabilities to third
                  parties or the general public before we can address and fix
                  the problematic systems.
                </li>
                <li>
                  Delete all data obtained as soon as the vulnerability is
                  fixed, or 1 month after its discovery, whichever comes first.
                </li>
              </ul>
              <p>
                In general, any action taken by you, the security researcher,
                should be limited to tests and sharing the information with us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer :disclaimerContent="1" />
  </div>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue";
import Footer from "@/components/partials/Footer";

export default {
	name: "SecurityBounty",
	components: {
		Navbar,
		Footer,
	},
	data() {
		return {
			headParams: {
				title: this.$t('meta.BOUNTY_TITLE'),
				description: this.$t('meta.BOUNTY_DESC'),
			},
		};
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: 'og-type' },
				{ property: "og:title", content: this.headParams.title, id: 'og-title' },
				{ property: "og:description", content: this.headParams.description, id: 'og-description' },
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_wall_logo.png",
          id: 'og-image'
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
          id: 'og-url'
				},
				{ property: "twitter:card", content: "summary_large_image", id: 'twitter-card' },
				{ property: "twitter:site", content: "@morpher_io", id: 'twitter-site' },
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_wall_logo.png",
          id: 'twitter-image'
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	mounted() {
		document.dispatchEvent(new Event("x-app-rendered"));
	},
	methods: {
		launchEmail() {
			window.open("mailto:security@morpher.com", "_self");
		},
	},
};
</script>

<style scoped>
.hero {
	background-color: #f6f7f8;
}
.mt-10 {
	margin-top: 10px;
}

.blog-container-column {
	max-width: 800px;
}
.blog {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.blog .title-font {
	font-size: 1.75rem;
}

.cta-btn.button {
	border-radius: 5px;
	border-width: 2px;
	color: #4c4c4c;
	font-weight: 700;
	font-size: 1.125rem;
	height: 2.875rem;
	padding: 0 2rem;
}
.cta-btn.button:hover {
	background-color: #00c386;
	border-color: #00c386;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-primary {
	color: #fff;
}
.cta-btn.button.is-primary:hover {
	background-color: #00d492;
	border-color: #00d492;
	box-shadow: 0px 2px 4px rgba(0, 161, 111, 0.1),
		0px 4px 10px rgba(0, 161, 111, 0.2);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
</style>
