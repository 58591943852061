<template>
  <div>
    <Navbar
      v-if="Object.keys(categories).length > 0"
      :blogCategories="categories"
      :currentCategory="currentCategory"
    />

    <section class="section bg-grey">
      <div class="container">
        <div class="has-text-centered has-text-left-mobile">
          <h1 class="title is-size-2 blog-title title-font">
            {{
              currentCategory === "all"
                ? $t("blog_title")
                : this.headParams.title
            }}
          </h1>
          <h2 class="subtitle is-size-5" v-text="$t('blog_subtitle')" />
        </div>

        <div class="columns is-multiline card-container">
          <div v-for="post in posts" :key="post.ID" class="column card-column">
            <BlogCard :post="post" />
          </div>
        </div>
      </div>
    </section>

    <br>
    <Footer :disclaimerContent="0" />
  </div>
</template>

<script>
import to from "await-to-js";
import axios from "axios";
import globalMixin from "@/mixins/global.mixin";
import Navbar from "@/components/partials/Navbar.vue";
import BlogCard from "@/components/partials/BlogCard.vue";
import Footer from "@/components/partials/Footer";

export default {
	name: "Blog",
	components: {
		Navbar,
		BlogCard,
		Footer,
	},
	mixins: [globalMixin],
	data() {
		return {
			categories: {},
			posts: [],
			currentCategory: "all",
			headParams: {
				title: this.$t("meta.BLOG_TITLE"),
				description: this.$t("meta.BLOG_DESC"),
			},
		};
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: "og-type" },
				{
					property: "og:title",
					content: this.headParams.title,
					id: "og-title",
				},
				{
					property: "og:description",
					content: this.headParams.description,
					id: "og-description",
				},
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_facebook.png",
					id: "og-image",
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
					id: "og-url",
				},
				{
					property: "twitter:card",
					content: "summary_large_image",
					id: "twitter-card",
				},
				{
					property: "twitter:site",
					content: "@morpher_io",
					id: "twitter-site",
				},
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_twitter.png",
					id: "twitter-image",
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	async mounted() {
		const html = document.querySelector('html');
		if (html) {
			html.setAttribute('lang', 'en');
		}
		// Do not fetch the categories from wordpress if page was pre-rendered
		if (document.getElementById('prerenderDataPosts')) {
			// Grab the data from global javascript component which contains prerender data
			this.categories = JSON.parse(document.getElementById('prerenderDataPosts').innerText);

		} else {
			const categories = {},
				[err, response] = await to(
					axios.get(
						"https://public-api.wordpress.com/rest/v1/sites/morpher.home.blog/categories"
					)
				);
			if (err) console.log(err);
			else if (response.status === 200) {
				if (response.data.found > 0) {
					response.data.categories.forEach((category) => {
						categories[category.slug] = {
							name: category.name,
							posts: [],
							slug: category.slug,
						};
					});
				}
			}

			await this.asyncForEach(Object.keys(categories), async (category) => {
				const [err_init, response_init] = await to(
					axios.get(
						"https://public-api.wordpress.com/rest/v1/sites/morpher.home.blog/posts/?category=" +
							category
					)
				);
				if (err_init) console.log(err_init);
				let posts = [];
				let pages = 1;
				if (response_init.status === 200) {
					pages = Math.ceil(response_init.data.found / 20);
					posts = posts.concat(response_init.data.posts);
				}
				if (pages > 1) {
					for (let page = 2; page <= pages; page++) {
						const [err, response] = await to(
							axios.get(
								"https://public-api.wordpress.com/rest/v1/sites/morpher.home.blog/posts/?category=" +
									category + "&page=" + page
							)
						);
						if (err) console.log(err);
						else if (response.status === 200) {
							posts = posts.concat(response.data.posts);
						}
					}
				}

				categories[category].posts = [];

				posts.forEach(post => {
					categories[category].posts.push({
						slug: post.slug,
						post_thumbnail: post.post_thumbnail,
						title: post.title,
						excerpt: post.excerpt,
						author: post.author,
						date: post.date,
						global_ID: post.global_ID,
					})
				});

			});

			this.categories = categories;

			// Store response from prerender into global javascript variable
			var script = document.createElement('TEXTAREA');
			script.id = 'prerenderDataPosts';
			script.style.display = 'none';

			script.innerText = JSON.stringify(categories) ;
			document.body.prepend(script);			
		}

		// Check if loading a blog category or main blog page
		if (this.$route.path.indexOf("category") > -1) {
			const category = this.$route.params.slug;
			this.switchPosts(category);
		} else {
			this.switchPosts("all");
		}

		// $crisp.push(["do", "chat:hide"]);
		document.dispatchEvent(new Event("x-app-rendered"));
	},
	methods: {
		switchPosts(category) {
			let newPosts = [];
			const postIDs = [];
			// Loop over all categories to show all posts, else just load posts from one blog category
			if (category === "all") {
				Object.keys(this.categories).forEach((categoryName) => {
					const currentPosts = this.categories[categoryName].posts;
					for (let i = 0; i < currentPosts.length; i++) {
						// Check if post already picked up in another cateogry by ID
						if (!postIDs.includes(currentPosts[i].global_ID)) {
							newPosts.push(currentPosts[i]);
							postIDs.push(currentPosts[i].global_ID);
						}
					}
				});
			} else {
				newPosts = this.categories[category].posts;
			}
			// Return posts sorted by date (newest to oldest)
			this.posts = newPosts.sort((firstPost, secondPost) =>
				secondPost.date > firstPost.date ? 1 : -1
			);
			this.currentCategory = category;
			// Update Head/Meta - Only if category page
			if (category !== "all") {
				if (category === "news-and-updates") {
					this.headParams.title = this.categories[category].name;
					this.headParams.description =
						"Company updates from the Morpher team. Learn what we've been working on.";
				} else {
					this.headParams.title =
						this.categories[category].name + " Blog Posts";
					this.headParams.description =
						this.firstLetterUppercase(category) +
						" insights from the startup that's democratizing investing with perfect liquidity trades.";
				}
				this.$emit("update-head");
			}
		},
	},
};
</script>
<style scoped>
.bg-grey {
	min-height: 100vh;
	margin-top: -15px;
}
.blog-title {
	padding-bottom: 10px;
}
.card-container {
	padding: 0 100px;
	margin-top: 15px;
}
.card-column {
	min-width: 280px;
	margin: 15px 5px;
	padding: 15px 15px;
}
.category-btn {
	margin-right: 15px;
	margin-bottom: 10px;
}
.category-btn.active {
	background-color: #00c386;
	color: white;
}
@media only screen and (max-width: 816px) {
	.card-container {
		padding: inherit;
	}
	.card-column {
		width: 100%;
	}
}
</style>
