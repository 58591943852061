<template>
  <div>
    <Hero />
    <Features />
    <Security />
    <Developers />
    <div style="height: 40px" />
    <!-- <TradeCTA /> -->
    <Footer :disclaimerContent="1" />
  </div>
</template>

<script>
import Hero from "@/components/wallet/Hero";
import Features from "@/components/wallet/Features";
import Security from "@/components/wallet/Security";
import Developers from "@/components/wallet/Developers";
// import TradeCTA from "@/components/wallet/TradeCTA";
import Footer from "@/components/partials/Footer";

export default {
	name: "Wallet",
	components: {
		Hero,
		Features,
		Security,
		Developers,
		// TradeCTA,
		Footer,
	},
	data() {
		return {
			headParams: {
				title: this.$t('meta.WALLET_TITLE'),
				description: this.$t('meta.WALLET_DESC')
			},
		};
	},
	head() {
		return {
			title: {
				inner: " ",
				separator: " ",
				complement: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: 'og-type' },
				{ property: "og:title", content: this.headParams.title, id: 'og-title' },
				{ property: "og:description", content: this.headParams.description, id: 'og-description' },
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_wallet_fb.png",
					id: 'og-image'
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
					id: 'og-url'
				},
				{ property: "twitter:card", content: "summary_large_image", id: 'twitter-card' },
				{ property: "twitter:site", content: "@morpher_io", id: 'twitter-site' },
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_wallet_tw.png",
					id: 'twitter-image'
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	mounted() {
		// $crisp.push(["do", "chat:hide"]);
		document.dispatchEvent(new Event("x-app-rendered"));
	},
};
</script>
<style scoped></style>
