<template>
  <div
    v-if="supportedWallet"
    class="mobileappbanner js_mobileappbanner"
    :class="{
      hidden: os == 'unknown' || closed,
      'mobileappbanner-ios': os == 'ios',
      'mobileappbanner-android': os == 'android',
    }"
  >
    <a
      href="#"
      class="mobileappbanner_exit js_mobileappbanner_exit"
      aria-label="Close"
      @click="closed = true"
    />
    <img class="mobileappbanner_icon" src="/img/mobile_icon.png">
    <div class="mobileappbanner_info has-text-left">
      <div>
        <div class="mobileappbanner_info_title">
          {{ $t("mobile.MORPHER_APP") }}
        </div>
        <div class="mobileappbanner_info_author">
          {{ $t("mobile.TAGLINE") }}
        </div>
        <div v-if="os == 'ios'" class="mobileappbanner_info_price">
          {{ $t("mobile.FREE_APP_STORE") }}
        </div>
        <div v-else class="mobileappbanner_info_price">
          {{ $t("mobile.FREE_GOOGLE_PLAY") }}
        </div>
      </div>
    </div>
    <a
      :href="link"
      class="mobileappbanner_button js_mobileappbanner_button"
      rel="noopener"
      aria-label="view"
    ><span class="mobileappbanner_button_label">{{
      $t("mobile.VIEW")
    }}</span></a>
  </div>
</template>
<script>
import { EventBus } from "../../store/event-bus";
import { userWalletType } from "@/utils/helpers";

export default {
	name: "MobileAppBanner",
	components: {},
	data: () => {
		return {
			os: "unknown",
			link: "",
			closed: false,
			supportedWallet: true,
		};
	},
	async mounted() {
		EventBus.$off("app-banner-show");
		EventBus.$on("app-banner-show", this.showAppBanner);
		// Hide from users that use an incompatible wallet (TEMPORARY)
		if (
			userWalletType() == "metamask" ||
			userWalletType() == "fortmatic" ||
			userWalletType() == "portis"
		) {
			this.supportedWallet = false;
		}
	},
	methods: {
		userWalletType,
		showAppBanner(data) {
			const os = this.getMobileOperatingSystem();
			this.link = data;
			this.os = os;
		},
		getMobileOperatingSystem() {
			var userAgent =
				window.navigator.userAgent ||
				window.navigator.vendor ||
				window.window.opera;
			if (
				userAgent.match(/iPad/i) ||
				userAgent.match(/iPhone/i) ||
				userAgent.match(/iPod/i)
			) {
				return "ios";
			} else if (userAgent.match(/Android/i)) {
				return "android";
			} else {
				return "unknown";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hidden {
	display: none;
}

.mobileappbanner {
	overflow-x: hidden;
	width: 100%;
	height: 84px;
	font-family: "Manrope", sans, sans-serif;
	background: rgba(255, 255, 255, 0.95);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.mobileappbanner_exit {
	position: relative;
	top: calc(50% - 6px);
	left: 9px;
	display: block;
	margin: 0;
	width: 12px;
	height: 12px;
	border: 0;
	text-align: center;
}

.mobileappbanner_exit::before,
.mobileappbanner_exit::after {
	position: absolute;
	width: 1px;
	height: 12px;
	background: #767676;
	content: " ";
}

.mobileappbanner_exit::before {
	transform: rotate(45deg);
}

.mobileappbanner_exit::after {
	transform: rotate(-45deg);
}

.mobileappbanner_icon {
	position: absolute;
	top: 10px;
	left: 30px;
	width: 64px;
	height: 64px;
	border-radius: 15px;
	background-size: 64px 64px;
}

.mobileappbanner_info {
	position: absolute;
	top: 10px;
	left: 104px;
	display: flex;
	overflow-y: hidden;
	width: 60%;
	height: 64px;
	align-items: center;
	color: #000;
}

.mobileappbanner_info_title {
	font-size: 16px;
	font-weight: 600;
}

.mobileappbanner_info_author,
.mobileappbanner_info_price {
	font-size: 12px;
	font-weight: 500;
}

.mobileappbanner_button {
	position: absolute;
	top: 32px;
	right: 10px;
	z-index: 1;
	display: block;
	padding: 0 12px;
	min-width: 10%;
	border-radius: 20px;
	background: #00c386;
	color: #fff;
	font-size: 18px;
	text-align: center;
	text-decoration: none;
	font-weight: 700;
}

.mobileappbanner_button_label {
	text-align: center;
}

@media screen and (min-width: 768px) {
	.mobileappbanner {
		display: none;
	}
}
</style>
