<template>
  <section class="hero">
    <Navbar />

    <!-- <div class="purple-callout has-text-centered">
			<a href="https://www.traderussianstocks.com" target="_blank">
				<b>📣 {{ $t("NEW") }}</b>
				{{ $t("MINI_ANNOUNCE") }}
			</a>
		</div> -->

    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-7-tablet is-6-desktop is-5-widescreen">
            <h1 class="title is-size-1 title-font" v-html="$t('hero_h1')" />
            <div class="column is-11-tablet is-paddingless">
              <p class="subtitle is-size-4" v-html="$t('hero_sub')" />
            </div>
            <div
              v-if="!isUserLoggedInTradingApp()"
              class="column pl-0 pt-20 mb-min-mobile"
            >
              <GetStartedButton />
            </div>
          </div>
          <div
            class="column is-narrow is-hidden-touch is-hidden-desktop-only"
          />
          <div class="column">
            <figure class="image is-5by3">
              <picture>
                <source
                  type="image/webp"
                  srcset="@/assets/img/home/hero_5by3.webp"
                >
                <source
                  type="image/png"
                  srcset="@/assets/img/home/hero_5by3.png"
                >
                <img
                  src="@/assets/img/home/hero_5by3.png"
                  :alt="$t('homepage_img')"
                >
              </picture>
            </figure>
          </div>
        </div>
        <FeaturedIn class="spacing-featured" />
      </div>
    </div>
  </section>
</template>
<script>
import Navbar from "@/components/partials/Navbar";
import FeaturedIn from "@/components/partials/FeaturedIn";
import GetStartedButton from "@/components/partials/GetStartedButton";
import { isUserLoggedInTradingApp } from "@/utils/helpers";

export default {
	name: "Hero",
	components: {
		Navbar,
		FeaturedIn,
		GetStartedButton,
	},
	methods: {
		isUserLoggedInTradingApp,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
	background-color: #fcf7f7;
	/* background-color: #f7fafc; */
	/* background-color: #fbf7fc; */
	/* background-color: #FFFAFA; */
	/* background-color: #eff3ff; */
}
.hero .subtitle {
	line-height: 1.5;
}
.pt-20 {
	padding-top: 10px;
}
.pt-40 {
	padding-top: 40px;
}
.pt-5 {
	padding-top: 5px;
}
.mt-20-tablet {
	margin-top: 20px;
}

.mt-55-tablet {
	margin-top: 30px;
}

.centered {
	display: flex;
	justify-content: center;
	align-items: center;
}

.spacing-featured {
	margin-top: 40px;
	justify-content: center;
}

@media only screen and (max-width: 1023px) {
	.is-center-touch {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
@media only screen and (max-width: 768px) {
	.is-center-mobile {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.hero.hero.is-fullheight {
		min-height: initial;
	}
	.spacing-featured {
		margin-top: -20px;
	}
	.mb-min-mobile {
		margin-bottom: -70px;
	}
}
@media only screen and (max-width: 500px) {
	.hero .title {
		margin-top: -20px;
	}
	.market-mobile-hide {
		display: none;
	}
	.text-left-mobile {
		text-align: left !important;
	}
	.mt-55-tablet {
		margin-top: inherit;
	}
	.mt-20-tablet {
		margin-top: inherit;
	}
	.spacing-featured {
		margin-top: inherit;
	}
}
@media only screen and (min-height: 1200px) {
	.hero.is-fullheight {
		min-height: 1200px;
	}
}

.pt-0 {
	padding-top: 0px;
	margin-top: -100px;
}
.pl-0 {
	padding-left: 0px;
}
.pt-35 {
	padding-top: 35px;
}
.mt-10 {
	margin-top: 10px;
}
.is-voffset {
	margin-bottom: -180px;
}
.is-26 {
	max-height: 26px;
}
.purple-callout {
	margin: 0 auto;
	margin-top: 10px;
	padding: 0.75rem 2rem;
	background-color: #fcfaff;
	border: 1px solid #d6bbfb;
	border-radius: 8px;
}
.purple-callout a {
	color: #6941c6;
	transition-delay: 0s;
	transition-duration: 0.25s;
	transition-property: all;
}
.purple-callout a:hover {
	text-decoration: underline;
}
</style>
