import { render, staticRenderFns } from "./Highlight3.vue?vue&type=template&id=a020febc&scoped=true"
import script from "./Highlight3.vue?vue&type=script&lang=js"
export * from "./Highlight3.vue?vue&type=script&lang=js"
import style0 from "./Highlight3.vue?vue&type=style&index=0&id=a020febc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a020febc",
  null
  
)

export default component.exports