<template>
  <div>
    <!-- Navbar Button -->
    <div v-if="navbar && registerURL">
      <button
        v-if="miniBtn"
        class="button mini-btn"
        :class="{ 'is-primary': navbarSticky }"
        @click="signup"
        v-text="$t(customText ? customText : 'airdrop_early_btn')"
      />
      <button
        v-else-if="navbarMobile"
        class="nav-btn button is-primary is-mobile-btn"
        @click="signup"
        v-text="$t(customText ? customText : 'airdrop_early_btn')"
      />
      <button
        v-else
        class="nav-btn button"
        :class="{ 'is-primary': navbarSticky }"
        @click="signup"
        v-text="$t(customText ? customText : 'airdrop_early_btn')"
      />
    </div>

    <div v-else-if="blogButton">
      <button
        class="cta-btn-blog button is-primary"
        @click="signup"
        v-text="
          customText
            ? $t(customText)
            : airdropPage
              ? $t('airdrop_email_btn')
              : $t('airdrop_early_btn')
        "
      />

    </div>

    <!-- Hero Button -->
    <div v-else class="button-spacing">
      <button
        class="cta-btn button is-primary"
        @click="signup"
        v-text="
          customText
            ? $t(customText)
            : airdropPage
              ? $t('airdrop_email_btn')
              : $t('airdrop_early_btn')
        "
      />
    </div>
  </div>
</template>

<script>

import { EventBus } from '../../store/event-bus';

import markets from '../../services/Markets';

import { isMobile } from 'mobile-device-detect';


export default {
	name: "GetStartedButton",
	props: {

		referredBy: {
			type: String,
			required: false,
			default: "",
		},
		customInvite: {
			type: String,
			required: false,
			default: "",
		},
		blogButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		airdropPage: {
			type: Boolean,
			required: false,
			default: false,
		},
		fromFooter: {
			type: Boolean,
			required: false,
			default: false,
		},
		navbar: {
			type: Boolean,
			required: false,
			default: false,
		},
		navbarMobile: {
			type: Boolean,
			required: false,
			default: false,
		},
		navbarSticky: {
			type: Boolean,
			required: false,
			default: false,
		},
		miniBtn: {
			type: Boolean,
			required: false,
			default: false,
		},
		customText: {
			type: String,
			required: false,
			default: "",
		},
		userAlreadyLoggedIn: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			registerURL: "",
			registerURLWeb: "",
			ip_location: ""
		};
	},
	watch: {
		referredBy() {
			this.updateRegisterURL()
    	},
		customInvite() {
			this.updateRegisterURL()
		}
  	},
	async mounted() {
		this.updateRegisterURL();
		
	},
	methods: {
		async signup() {
			this.updateRegisterURL();
			// Record Google Analytics Event
			// prettier-ignore
			await this.$gtag.event('website_get_started', {
					event_category: this.fromFooter ? "footer_click" :  (this.navbar ? "navbar_click" : "hero_click" ),
					event_label: this.referredBy ? "referred" : ( this.customInvite ? "custom_invite" : "")
				});				

			// Forward user to registerURL
			if (!this.userAlreadyLoggedIn) {
				if (isMobile) {
					window.location.href = this.registerURL;
				} else {
					window.location.href = this.registerURLWeb;
				}
				
			}
			else
				window.location.href =
					process.env.VUE_APP_APPLICATION_URL || "https://morpher.com/trade";
		},
		async updateRegisterURL() {
			this.ip_location = await markets.checkLocation()

			// Grab custom invite / referral from storage if on non-Airdrop page
			const referred_by = localStorage.getItem("airdrop_referred_by");
			const custom_invite = localStorage.getItem("airdrop_custom_invite");


			const utm_source = localStorage.getItem('utm_source');
			const utm_medium = localStorage.getItem('utm_medium');
			const utm_campaign = localStorage.getItem('utm_campaign');
			const utm_term = localStorage.getItem('utm_term');
			const utm_content = localStorage.getItem('utm_content');
			const at_gd = localStorage.getItem('at_gd');
			const utm_referrer = localStorage.getItem('utm_referrer');
			const ref = localStorage.getItem('ref');
			const source_id = localStorage.getItem('source_id');
			const affiliate_referrer = localStorage.getItem('affiliate_referrer');
			const affiliate_id = localStorage.getItem('affiliate_id');
			const affiliate_campaign = localStorage.getItem('affiliate_campaign');
			const affiliate_utm_source = localStorage.getItem('affiliate_utm_source');
			const affiliate_utm_medium = localStorage.getItem('affiliate_utm_medium');
			const affiliate_utm_campaign = localStorage.getItem('affiliate_utm_campaign');
			const affiliate_path = localStorage.getItem('affiliate_path');
			const affiliate_language = localStorage.getItem("affiliate_language");
			let affiliate_browser_language = localStorage.getItem("affiliate_browser_language");

			if (!affiliate_browser_language) {
				const browser_language = navigator.language || navigator.userLanguage; //no ?s necessary
				if (browser_language) {
					affiliate_browser_language = browser_language
				}
			}

			let urlWeb = (process.env.VUE_APP_APPLICATION_URL || 'https://morpher.com/trade') +	'/register'
			let url = process.env.VUE_APP_MOBILE_LINK || 'https://morph.dynalinks.app{path}'
			
			url = url.replace('{path}', '/register')
			//url +=`&adj_fallback=https%3A%2F%2F${process.env.VUE_APP_REGISTER_URL.replace(/\//g,'%2F')}&adj_redirect_macos=https%3A%2F%2F${process.env.VUE_APP_REGISTER_URL.replace(/\//g,'%2F')}`
			
			// Check for custom invites or referrals to generate registerURL
			if (this.customInvite || this.referredBy) {
				
				url += (this.customInvite ? "?custom_invite=" + this.customInvite : "/?referred_by=" + this.referredBy);
				urlWeb += (this.customInvite ? "/?custom_invite=" + this.customInvite : "/?referred_by=" + this.referredBy);
			} else if (custom_invite || referred_by) {
				url += (custom_invite ? "&custom_invite=" + custom_invite: "&referred_by=" + referred_by);
				urlWeb += (custom_invite ? "/?custom_invite=" + custom_invite : "/?referred_by=" + referred_by);
			} else {
				urlWeb += '/?referred_by='
				url += '?referred_by='
			}

			if (this.blogButton) {

				urlWeb += '&candlesticks=true'
				
			}



			if (utm_campaign) urlWeb +='&utm_campaign=' + utm_campaign;
			if (utm_source) urlWeb +='&utm_source=' + utm_source;
			if (utm_medium) urlWeb +='&utm_medium=' + utm_medium;
			if (utm_term) urlWeb +='&utm_term=' + utm_term;
			if (utm_content) urlWeb +='&utm_content=' + utm_content;
			if (at_gd) urlWeb +='&at_gd=' + at_gd;
			if (utm_referrer) urlWeb +='&utm_referrer=' + utm_referrer;
			if (ref) urlWeb +='&ref=' + ref;
			if (source_id) urlWeb +='&source_id=' + source_id;
			if (affiliate_referrer) urlWeb +='&affiliate_referrer=' + affiliate_referrer;
			if (affiliate_id) urlWeb +='&affiliate_id=' + affiliate_id;
			if (affiliate_campaign) urlWeb +='&affiliate_campaign=' + affiliate_campaign;
			if (affiliate_utm_source) urlWeb +='&affiliate_utm_source=' + affiliate_utm_source;
			if (affiliate_utm_medium) urlWeb +='&affiliate_utm_medium=' + affiliate_utm_medium;
			if (affiliate_utm_campaign) urlWeb +='&affiliate_utm_campaign=' + affiliate_utm_campaign;
			if (affiliate_language) urlWeb +='&affiliate_language=' + affiliate_language;
			if (affiliate_browser_language) urlWeb +='&affiliate_browser_language=' + affiliate_browser_language;
			
			if (affiliate_path) urlWeb +='&affiliate_path=' + affiliate_path;


			EventBus.$emit('app-banner-show', url);

			this.registerURL = url;
			this.registerURLWeb = urlWeb;

			
			console.log(' isMobile',  isMobile, this.registerURL, this.registerURLWeb)
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-btn.button {
	border-radius: 5px;
	border-width: 2px;
	color: #4c4c4c;
	font-weight: 700;
	font-size: 1rem;
	height: 38px;
	padding: 0px 25px;
}
.nav-btn.button:hover {
	background-color: #00c386;
	border-color: #00c386;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.nav-btn.button.is-primary {
	color: #fff;
}
.nav-btn.button.is-primary:hover {
	background-color: #00d492;
	border-color: #00d492;
	box-shadow: 0px 2px 4px rgba(0, 161, 111, 0.1),
		0px 4px 10px rgba(0, 161, 111, 0.2);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.nav-btn.button.is-primary.is-mobile-btn {
	width: 100%;
	margin: 10px 0;
	font-size: 1.25rem;
	height: 3rem;
}

.mini-btn {
	border-radius: 5px;
	border-width: 2px;
	color: #4c4c4c;
	font-weight: 700;
	font-size: 0.825rem;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.mini-btn:hover {
	background-color: #00c386;
	border-color: #00c386;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.mini-btn.is-primary {
	color: #ffffff;
}
.mini-btn.is-primary:hover {
	background-color: #00d492;
	border-color: #00d492;
	box-shadow: 0px 2px 4px rgba(0, 161, 111, 0.1),
		0px 4px 10px rgba(0, 161, 111, 0.2);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.button-spacing {
	margin-top: 20px;
	margin-bottom: 80px;
}
.cta-btn.button {
	border-radius: 30px;
	border-width: 2px;
	color: #4c4c4c;
	font-weight: 700;
	font-size: 1.125rem;
	height: 2.875rem;
	padding: 0 2rem;
}

.cta-btn-blog.button {
	border-radius: 8px;
	border-width: 2px;
	color: #FFF;
	font-weight: 700;
	font-size: 1.125rem;
	
	

	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;	
	height: 40px;
	width: 100%;
}
.cta-btn.button:hover {
	background-color: #00c386;
	border-color: #00c386;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-primary {
	color: #fff;
}
.cta-btn.button.is-primary:hover {
	background-color: #00d492;
	border-color: #00d492;
	box-shadow: 0px 2px 4px rgba(0, 161, 111, 0.1),
		0px 4px 10px rgba(0, 161, 111, 0.2);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
</style>
