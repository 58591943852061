<template>
  <div>
    <Hero
      :airdropAmount="airdropAmount"
      :referralBonus="referralBonus"
      :customInvite="customInvite"
      :inviteMessage="inviteMessage"
    />
    <Features />
    <Footer :disclaimerContent="2" :emptyFooter="customInvite !== ''" />
  </div>
</template>

<script>
import Hero from "@/components/invite_ru/Hero";
import Features from "@/components/invite_ru/Features";
import Footer from "@/components/partials/Footer";

export default {
	name: "CustomInviteRussian",
	components: {
		Hero,
		Features,
		Footer,
	},
	props: {
		airdropAmount: Number,
		referralBonus: Number,
		customInvite: String,
		inviteMessage: String,
	},
	data() {
		return {
			headParams: {
				title: this.$t("meta.AIRDROP_TITLE"),
				description: this.$t("meta.AIRDROP_DESC"),
			},
		}
	},
	head() {
    return {
      title: {
        inner: this.headParams.title,
      },
      meta: [
        {
          name: "description",
          content: this.headParams.description,
          id: "desc",
        },
        { property: "og:type", content: "website", id: "og-type" },
        {
          property: "og:title",
          content: this.headParams.title,
          id: "og-title",
        },
        {
          property: "og:description",
          content: this.headParams.description,
          id: "og-description",
        },
        {
          property: "og:image",
          content: "https://www.morpher.com/img/og_airdrop_fb.png",
          id: "og-image",
        },
        {
          property: "og:url",
          content: "https://www.morpher.com" + this.$route.path,
          id: "og-url",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
          id: "twitter-card",
        },
        {
          property: "twitter:site",
          content: "@morpher_io",
          id: "twitter-site",
        },
        {
          property: "twitter:image",
          content: "https://www.morpher.com/img/og_airdrop_tw.png",
          id: "twitter-image",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.morpher.com" + this.$route.path,
          id: "canonical",
        },
      ],
    };
  },
};
</script>
