import axios from "axios";
import { i18n } from '../plugins/i18n';

axios.defaults.baseURL = process.env.VUE_APP_API;

export default {
	subscribe(self) {
        try {
            // If submitted with bad email
            if (self.email === "") {
                self.loading = false;
                self.emailValidation = false;
                self.emailMessage = this.$t('USERREG_EMAILEMPTY');

                return;
            }
            // If error came up
            if (self.hasError) {
                self.email = "";
                self.badFeedbackMessage = true;
                self.badFeedbackVisual = true;
                setTimeout(() => {
                    self.resetSate();
                }, 5000);
                return;
            }
            // Show loading status if non empty email submission
            if (self.email === "") {
                return;
            // Start reCAPTCHA and save email in case start typing again
            } else {
                self.loading = true;
                self.submittedEmail = self.email;
                self.waitForGeocheck();
                return;
            }
        } catch (err) {
            console.log("Error in subscribe: " + err.message)
        }
    },
    /*
    * Start reCaptcha and validate email format before fetching from Airdrop list. Helps prevent bot unlocking emails.
    */
    startLogin(self) {
        try {
            // If submitted with bad email
            if (self.email === "") {
                self.loading = false;
                self.emailValidation = false;
                self.emailMessage = this.$t('USERREG_EMAILEMPTY');
                return;
            }
            // If error came up
            if (self.hasError) {
                self.email = "";
                self.badFeedbackMessage = true;
                self.badFeedbackVisual = true;
                setTimeout(() => {
                    self.resetSate();
                }, 5000);
                return;
            }
            // Show loading status if non empty email submission
            if (self.email === "") {
                return;
            // Start reCAPTCHA and save email in case start typing again
            } else {
                self.loading = true;
                self.submittedEmail = self.email;
                return;
            }
        } catch (err) {
            console.log("Error in subscribe: " + err.message)
        }
    },
    /*
    * submit an email to the subscription email list. this uses the back end web endpoint.
    */
    async submitSubscribe(self) {
        axios
        .post('/web/subscribe', {
          email: self.submittedEmail,
          recaptchaToken: self.recaptchaToken,
          ip_address: (self.location) ? self.location.ip : '',
          ip_country: (self.location) ? self.location.country_code : ''
        })
        .then((response) => {
            if (response.status === 200) {
                if (!response.data.success) {
                    // If an error was returned then process the error test
                    const strError = (response.data.returnMessage || response.data.error)
                    .replace('EmailFormat', this.$t('USERREG_EMAILFORMAT'))
                    .replace('EmailMXRecord', this.$t('USERREG_EMAILMX'))
                    .replace('EmailBlacklisted', this.$t('USERREG_EMAILBLACKLIST'))
                    .replace('EmailAlreadyUsed', this.$t('USERREG_EMAILALREADYUSED'));
                    
                    self.loading = false;
                    self.emailValidation = false;
                    self.emailMessage = strError;

                } else {
                    // Return the subscription results
                    self.emailValidation = true;
                    self.emailMessage = '';
                    self.loading = false;
                    // Submit subscription to GA

                    self.$gtag.event('email_subscribe', {
                        event_category: (self.fromFooter) ? "footer_submit" : "hero_submit",
                        event_label: "join_waitlist"
                    });	                    
                                        
                    if (response.data.airdrop_uuid) {
                        self.$router.push('/airdrop/' + response.data.airdrop_uuid);
                    } else {
                        self.$router.push('/waitlist');
                    }
                    // // Accept user cookies
                    // utils.cookiesAccepted(self);
                    // // Generate URL to forward all url parameters for better tracking
                    // const routes = window.location;
                    // let urlVars = "";
                    // if (routes.pathname !== "/") {
                    //     urlVars += "&path=" + routes.pathname.substring(1);
                    //     const slashIndex = urlVars.indexOf("/");
                    //     if (slashIndex > -1) urlVars = urlVars.substring(0, slashIndex);
                    // }
                    // urlVars += "&" + routes.search.substring(1);
                    // // Redirect to the app registration page
                    // window.location.href = process.env.VUE_APP_APPLICATION_URL + "/register?email=" + self.submittedEmail + urlVars;
                }
          } else {
            self.loading = false;
            self.hasError = true;
          }
          self.$refs.recaptcha.reset();
          self.submittedEmail = "";
        })
        // Should never enter here
        .catch(() =>  {
          self.hasError = true;
          self.loading = false;
        });
    },
    /*
    * Get the subscription details for an email address. this is used by the unsubscripbe front end.
    */
    async getSubscription(self) {
        try {
            const response = await axios.get('/web/subscription/' + self.$route.query.id);
            if (response.status === 200) {

                if (response.data.success) {
                    self.is_registered = response.data.is_registered || false;
                    if ( response.data.email) {
                        const subs = response.data.subscription;
                        // Converting strings on backend to booleans
                        Object.keys(subs).map(key => {
                            
                            if (subs[key] === 'true' || subs[key] === true) self.subscription[key] = true;
                        })
                    }
                    // else self.$router.push('/');
                } else {
                    console.log('Error', response.data)
                    self.$router.push('/');
                }
            }
        } catch (e) {
            console.log(e);
        }
    },
    /*
    * Get the airdrop info from the backend for a given uuid
    */
    async getAirdrop(uuid, locationCheck = '') {
        try {
            const response = await axios.get('/airdrop/fetch/' + uuid + '?locationCheck=' + locationCheck);
            if (response.status === 200) {
                if (response.data.message === 'success') {
                    return response.data
                    // Set the returned info to properties on the calling control
                    // tokens_earned: response.data.tokens_earned,
                    // num_referrals: response.data.num_referrals,
                    // kyc_status: response.data.kyc_status,
                    // full_name: response.data.full_name,
                    // airdropValue:  response.data.airdropValue,
                    // ranking:  response.data.ranking,
                    // referral_id:  response.data.referral_id,
                    // geo_block: response.data.geo_block
                    
                } else return { Error: response.data.message }
            }
            return null;
        } catch (e) {
            return { Error: e.message }
        }
    },
    /*
    * Get the airdrop invites list from the backend for a given uuid
    */
   async getAirdropInvites(uuid) {
    try {
        const response = await axios.get('/airdrop/fetchInvites/' + uuid);
        if (response.status === 200) {
            return response.data
        }
        return null;
    } catch (e) {
        return { Error: e.message }
    }
},
    /*
    * Get the current airdrop values from the backend
    */
    async getAirdropAmount(referredBy) {
        try {
            referredBy = referredBy || '';
            const response = await axios.get('/airdrop/airdropAmount/' + referredBy);
            if (response.status === 200) {
                if (response.data.success) {
                    
                    return response.data;
                    
                } else return { Error: response.data.message }
            }
            return null;
        } catch (e) {

            return { Error: e.message }
        }
    },
    /*
    * Pass email in uuid to check if valid Airdrop account exists, throw error otherwise.
    */
   async getAirdropLogin(self) {
    const email = self.submittedEmail;
    if (!email) {
        return;
    }
    
    try {
        // limit the user to 5 retries for email logon
        const retryCount = parseInt(localStorage.getItem('emailAttempts')) || 0;
        if (retryCount > 4) {
            self.Snackbar.open({position: 'is-bottom-left', message: 'Too many incorrect email login attempts.'});
            return;
        }
        
        const response = await axios.get('/airdrop/fetchEmail/' + email);
        if (response.status === 200) {

            if (response.data.message === 'success') {
                self.emailValidation = true;
                self.emailMessage = '';
                self.loading = false;
                // Send to Airdrop page
                self.$router.push({ path: '/airdrop/' + response.data.uuid })
                self.$emit('reload-airdrop-status');
                
            } else { 
                localStorage.setItem('emailAttempts', retryCount + 1);
                
                self.loading = false;
                self.emailValidation = false;
                // self.emailMessage = response.data.message;
                self.$buefy.snackbar.open({position: 'is-bottom-left', message: 'Email does not exist in Airdrop list.'});
            }
        }
        self.$refs.recaptcha.reset();
        self.submittedEmail = "";
    } catch (e) {
        self.loading = false;
        self.hasError = true;
    }
},
    
    /*
    * Submit the airdrop registration and referral info to the back end
    */
    submitAirdrop(self) {
        // Read from localStorage in case user browsed around before submitting info
        const localReferral = localStorage.getItem("airdrop_referred_by");
        const localInvite = localStorage.getItem("airdrop_custom_invite");

        axios.post('/airdrop/submit', {
          email: self.submittedEmail,
          referred_by: self.referredBy || localReferral,
          custom_invite: self.customInvite || localInvite,
          recaptchaToken: self.recaptchaToken,
          ip_address: (self.location) ? self.location.ip : '',
          ip_country: (self.location) ? self.location.country_code : ''
        })
        .then((response) => {

            if (response.status === 200) {
                if (!response.data.success) {
                    // Check for an email validation errors if there was an error response
                    const strError = (response.data.returnMessage || response.data.error)
                    .replace('EmailFormat', this.$t('USERREG_EMAILFORMAT'))
                    .replace('EmailMXRecord', this.$t('USERREG_EMAILMX'))
                    .replace('EmailBlacklisted', this.$t('USERREG_EMAILBLACKLIST'))
                    .replace('EmailAlreadyUsed', this.$t('USERREG_EMAILALREADYUSED'));
                    // reset the calling control status
                    self.loading = false;
                    self.emailValidation = false;
                    self.emailMessage = strError;
                } else {
                    if (response.data.error) {
                        if (response.data.error === 'COUNTRY_BLOCKED') {
                            //// Forward to the country blocked page
                            self.$router.push({ path: '/airdrop/country' });
                        } else {
                            // Write the error back to the calling control
                            self.loading = false;
                            self.emailValidation = false;
                            self.emailMessage = response.data.error;
                        }
                    } else {
                        // Reset the calling control status
                        self.emailValidation = true;
                        self.emailMessage = '';
                        self.loading = false;
                        localStorage.removeItem("airdrop_referred_by");
                        localStorage.removeItem("airdrop_custom_invite");
                        // Submit subscription to GA

                        self.$gtag.event('email_subscribe', {
                            event_category: (self.fromFooter) ? "footer_submit" : "hero_submit",
                            event_label: (self.referredBy || localReferral) ? "referred" : ( (self.customInvite || localInvite) ? "custom_invite" : "")
                        });	                    
    
                        // Forward to the Airdrop to show the users airdrop stats once they have registered
                        self.$router.push({ path: '/airdrop/email/' + response.data.uuid })
                    }
                }
            } else {
                self.loading = false;
                self.hasError = true;
            }
            
            self.$refs.recaptcha.reset();
            self.submittedEmail = "";
        })
        // Should never enter here
        .catch(() =>  {
            self.hasError = true;
            self.loading = false;
        });
    },
    /*
    * Submit the kyc callback code to the backend so that the backend can store the kyc data and flag the airdrop recod as confirmed.
    */
    async confirmKYC(uuid, kyc_uuid) {
        
        try {
            const response = await axios.post(
                  '/airdrop/confirmKYC'
                ,   {
                        uuid,
                        kyc_uuid
                    });

            
            if (response.data.message === 'success') {
                // Set the returned info to properties on the calling control
                return response.data;
                
            } else return { Error: response.data.message }
        
       } catch(err) {
            return { Error: err.message }
       }
    },
    
    /*
    * Resend the verification email to the user
    */
    async requestAnonymousLink(uuid) {
        try {
            const response = await axios.post(
                '/airdrop/requestAnonymousLink'
                ,   {
                        uuid
                    });

                
                if (response.data.message === 'success') {
                    // Set the returned info to properties on the calling control
                    return response.data;
                    
                } else return { Error: response.data.message }
            
        } catch(err) {
                return { Error: err.message }
        }
    },
    /*
    * Resend the verification email to the user
    */
    async resendVerificationEmail(uuid) {
        
        try {
            const response = await axios.post(
                  '/airdrop/resendVerificationEmail'
                ,   {
                        uuid
                    });

            
            if (response.data.message === 'success') {
                // Set the returned info to properties on the calling control
                return response.data;
                
            } else return { Error: response.data.message }
        
       } catch(err) {
            return { Error: err.message }
       }
    },
    /*
    * Verify the user email address.
    */
    async verifyEmail(token) {
  
        try {
            const response = await axios.post(
                  '/airdrop/verifyEmail'
                ,   {
                        token
                    });

            
            if (response.data.message === 'success') {
                // Set the returned info to properties on the calling control
                return response.data;
                
            } else return { Error: response.data.message }
        
       } catch(err) {
            return { Error: err.message }
       }
    },

    /*
    * submit the email list unsubscribe data to the back end in order to remove an email from a mailing list.
    */
    async unsubscribeSubmit(self) {

        try {
            const response = await axios.post('/web/unsubscribe/' + self.$route.query.id, { subscription: self.subscription, recaptchaToken: self.recaptchaToken })
            if (response.status === 200) {
                self.loading = false;
                self.loadingUpdate = false;
                self.$refs.recaptcha.reset();
                if (response.data.success) {
                    // Check if unsubscribing from one type of email only
                    if (self.singleUnsub.length > 0) {
                        self.unsubscribed = true;
                        return;
                    }
                    // Check if unsubscribed from all or not
                    for (const sub in self.subscription) {
                        if (self.subscription[sub] === true) {
                            self.$buefy.snackbar.open({position: 'is-bottom-left', message: i18n.t('update_unsub_message')});
                            return;
                        }
                    }
                    // Otherwise unsubscribing from all
                    self.unsubscribed = true;
                    
                }
                else {
                    console.log('Error', response.data);
                    self.$buefy.snackbar.open({position: 'is-bottom-left', message: i18n.t('error_unsub_message')});
                }
            }
        } catch (e) {
            console.log(e);
            self.$refs.recaptcha.reset();
            self.$buefy.snackbar.open({position: 'is-bottom-left', message: i18n.t('error_unsub_message')});
            self.loading = false;
            self.loadingUpdate = false;
        }
    }

}
