<template>
  <!-- Language -->
  <div
    v-click-outside="closeDropdown"
    class="language-selector has-line-height-1"
  >
    <div
      :class="{
        dropdown: true,
        active: dropdownOpen,
      }"
    >
      <button
        class="button lang-button is-size-14 dark-btn has-text-weight-normal"
        @click="dropdownOpen = !dropdownOpen"
      >
        <img
          :src="
            require('../assets/img/flags/' +
              $i18n.locale.split('-')[0].toLowerCase() +
              '.svg')
          "
        >
        <span class="has-text-weight-medium lang-name">{{
          getLanguageParameter($i18n.locale, "name")
        }}</span>
        <i
          class="fas fa-chevron-up arrow"
          :class="{
            active: dropdownOpen,
          }"
        />
      </button>

      <div class="dropdown-items">
        <div
          v-for="language in languages"
          :key="language.code"
          class="lang-item has-text-grey-faded"
          @click="() => setLanguage(language.code)"
        >
          <img
            :src="
              require('../assets/img/flags/' +
                language.code.toLowerCase() +
                '.svg')
            "
          >
          <span>{{ language.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Cookie from "js-cookie";

import { activeLanguagesList, getLanguageParameter } from "../main";

export default {
	name: "LanguageSelector",
	data() {
		return {
			dropdownOpen: false,
			languages: activeLanguagesList,
		};
	},
	methods: {
		getLanguageParameter(code, parameter) {
			return getLanguageParameter(code, parameter);
		},
		setLanguage(lang) {
			this.dropdownOpen = false;
			if (lang !== this.$i18n.locale) {
				const path = ((lang !== 'en' ? '/' + lang : '') + location.pathname.replace('/' + this.$i18n.locale, ''));

				this.$i18n.locale = lang;
				document.querySelector("html")?.setAttribute("lang", lang);
				if (lang === "ar")
					document.querySelector("html")?.setAttribute("dir", "rtl");
				else document.querySelector("html")?.setAttribute("dir", "");
				Cookie.set("locale", lang);

				const baseURL = process.env.BASE_URL ? (process.env.BASE_URL === '/' ? '' : process.env.BASE_URL) : '';

				const address = location.protocol + "//" + location.host + baseURL + path;
				window.location.replace(address);
			}
		},
		closeDropdown() {
			if (this.dropdownOpen) {
				this.dropdownOpen = false;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.language-selector {
	display: flex;
	align-items: center;
	margin-left: auto;
	.dropdown {
		position: relative;
		.dropdown-items {
			position: absolute;
			bottom: 25px;
			left: 0;
			background: #fff;
			border: 1px solid #eae9ed;
			box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.1);
			border-radius: 13px;
			padding-top: 0;
			padding-bottom: 0;
			overflow: hidden;
			margin-bottom: 0.5rem;
			opacity: 0;
			visibility: hidden;
			transition: 200ms;
			min-width: 180px;
			.lang-item {
				text-align: left;
				padding: 0.5rem 0.5rem;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				+ .lang-item {
					border-top: 1px solid #eae9ed;
				}
			}
		}
		&.active {
			.dropdown-items {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	.lang-button {
		display: flex;
		align-items: center;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		height: 30px;
		width: auto;
		border: none;
		border-radius: 0;
		box-shadow: none;
		background: transparent;
		padding: 0;
		> img {
			margin-right: 5px;
			width: 18px;
			height: 18px;
		}
		.lang-name {
			margin-right: 5px;
		}
		.arrow {
			transition: 200ms;
			transform-origin: center;

			&.active {
				transform: rotateZ(180deg);
			}
		}
	}
	.lang-item {
		display: flex;
		align-items: center;
		&:hover {
			cursor: pointer;
			background-color: #f5f3f8;
			text-decoration: none;
		}
		> img {
			margin-right: 5px;
			width: 18px;
			height: 18px;
		}
	}
}
</style>
