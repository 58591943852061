<template>
  <div>
    <section id="custom_section" class="hero" style="visibility: hidden;">
      <Navbrand />
      <iframe
        id="custom_frame"
        :src="'/custom_pages/' + page + '.html'"
        scrolling="no"
        class="custom_page_content"
      />

      <Footer :emptyFooter="true" :disclaimerContent="2" />
    </section>
  </div>
</template>

<script>
import Footer from "@/components/partials/Footer";
import Navbrand from "@/components/partials/Navbrand.vue";
import Cookie from "js-cookie";

export default {
	name: "Custom",
	components: {
		Footer,
		Navbrand,
	},
	data() {
		return {
			page: this.$router.currentRoute.path.substring(
				this.$router.currentRoute.fullPath.lastIndexOf("/") + 1
			),
			setHeight: null,
			headParams: {
				title: this.$t("CUSTOM_PAGE_TITLE"),
				description: this.$t("CUSTOM_PAGE_TITLE"),
			},
		};
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta: [
			    { id: "robots", name: "robots", content: "noindex" },
				{ hid: "robots", name: "robots", content: "noindex" },
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				// { property: "og:type", content: "website", id: 'og-type' },
				// { property: "og:title", content: this.headParams.title, id: 'og-title' },
				// { property: "og:description", content: this.headParams.description, id: 'og-description' },
				// {
				// 	property: "og:image",
				// 	content: "https://www.morpher.com/img/og_wall_logo.png",
				// 	id: 'og-image'
				// },
				// {
				// 	property: "og:url",
				// 	content: "https://www.morpher.com" + this.$route.path,
				// 	id: 'og-url'
				// },
				// { property: "twitter:card", content: "summary_large_image", id: 'twitter-card' },
				// { property: "twitter:site", content: "@morpher_io", id: 'twitter-site' },
				// {
				// 	property: "twitter:image",
				// 	content: "https://www.morpher.com/img/og_wall_logo.png",
				// 	id: 'twitter-image'
				// },
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
	beforeMount() {
		if (this.$router.currentRoute.path.includes("-ru")) this.setLanguage("ru");
		else this.setLanguage("en");
	},
	mounted() {
		var meta = document.createElement('meta');
		meta.name = "robots";
		meta.content = "noindex";
		document.getElementsByTagName('head')[0].appendChild(meta);

		if (this.setHeight) {
			clearTimeout(this.setHeight);
		}

		const checkHeight = this.checkHeight;
		document.getElementById("custom_frame").onload = function () {
			checkHeight();

			document.dispatchEvent(new Event("x-app-rendered"));
		};
		// $crisp.push(["do", "chat:hide"]);
	},
	methods: {
		checkHeight() {
			try {
				if (this.setHeight) {
					clearTimeout(this.setHeight);
				}
				// set the iframe height to the height of the content
				document.getElementById("custom_frame").style.height =
					document.getElementById("custom_frame").contentWindow.document.body
						.offsetHeight + "px";

				if (document.getElementById('custom_section').style.visibility == "hidden") {
					document.getElementById('custom_section').style.visibility = "visible";
				}						

				this.setHeight = setTimeout(() => {
					this.checkHeight();
				}, 100);
			} catch (err) {}
		},
		setLanguage(lang) {
			if (lang !== this.$i18n.locale) {
				// const path = ((lang !== 'en' ? '/' + lang : '') + location.pathname.replace('/' + this.$i18n.locale, ''));

				this.$i18n.locale = lang;
				document.querySelector("html")?.setAttribute("lang", lang);
				if (lang === "ar")
					document.querySelector("html")?.setAttribute("dir", "rtl");
				else document.querySelector("html")?.setAttribute("dir", "");
				Cookie.set("locale", lang);

				// const baseURL = process.env.BASE_URL ? (process.env.BASE_URL === '/' ? '' : process.env.BASE_URL) : '';

				// const address = location.protocol + "//" + location.host + baseURL + path;
				// window.location.replace(address);
			}

			this.headParams.title = this.$t("CUSTOM_PAGE_TITLE");
			this.headParams.description = this.$t("CUSTOM_PAGE_TITLE");
		},
	},
};
</script>
<style scoped>
.hero {
	background-color: #d0d9ee;
}
.custom_page_content {
	width: 100%;
	min-height: 500px;
	overflow: hidden;
}
</style>